// src/redux/store.js
import { configureStore } from "@reduxjs/toolkit";
import servicesReducer from "./servicesSlice";
import brandsReducer from "./brandsSlice";
import modelsReducer from "./modelsSlice";
import trendingReducer from "./tranding";
import FranchiseformSlice from "./FranchiseformSlice";

const store = configureStore({
 reducer: {
  services: servicesReducer,
  brands: brandsReducer,
  models: modelsReducer,
  models: modelsReducer,
  trending: trendingReducer,
  Franchise: FranchiseformSlice,
 },
});

export default store;
