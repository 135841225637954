import React, { useState } from "react";
import {
 Modal,
 ModalOverlay,
 ModalContent,
 ModalHeader,
 ModalCloseButton,
 ModalBody,
 Input,
 Grid,
 Box,
 Text,
 Flex,
} from "@chakra-ui/react";

const TransmissionModal = ({
 isOpen,
 onClose,
 transmissions = [],
 onSelect,
}) => {
 const [searchQuery, setSearchQuery] = useState("");

 // Filter transmissions based on the search query
 const filteredTransmissions = transmissions?.filter((transmission) =>
  transmission?.toLowerCase().includes(searchQuery.toLowerCase())
 );

 const handleSelect = (transmission) => {
  console.log("Transmission selected:", transmission);
  onSelect(transmission);
  onClose();
 };

 return (
  <Modal isOpen={isOpen} onClose={onClose} isCentered>
   <ModalOverlay />
   <ModalContent width="700px" maxWidth="700px" maxHeight="80vh" mx="auto">
    <ModalHeader>Select Transmission Type</ModalHeader>
    <ModalCloseButton />

    {/* Search Input */}
    <Flex
     position="sticky"
     top="0"
     bg="white"
     zIndex="1000"
     p={4}
     borderBottom="1px solid #eaeaea"
    >
     <Input
      placeholder="Search Transmission Type"
      mb={4}
      value={searchQuery}
      onChange={(e) => setSearchQuery(e.target.value)}
      border="1px solid #ddd"
      borderRadius="md"
      padding="10px"
      fontSize="16px"
      _focus={{ borderColor: "blue.500", boxShadow: "0 0 0 1px blue.500" }}
      _hover={{ borderColor: "blue.300" }}
     />
    </Flex>

    <ModalBody
     overflowY="auto"
     maxHeight="60vh"
     pt="0"
     sx={{
      "::-webkit-scrollbar": { display: "none" },
      "-ms-overflow-style": "none",
      "scrollbar-width": "none",
     }}
    >
     <Grid templateColumns="repeat(auto-fit, minmax(100px, 1fr))" gap={6}>
      {filteredTransmissions.length > 0 ? (
       filteredTransmissions.map((transmission, index) => (
        <Box
         key={index}
         textAlign="center"
         cursor="pointer"
         onClick={() => handleSelect(transmission)}
         _hover={{ bg: "gray.100", borderRadius: "md" }}
         p={2}
        >
         <Text mt={2} fontWeight="bold">
          {transmission}
         </Text>
        </Box>
       ))
      ) : (
       <Text>No results found</Text>
      )}
     </Grid>
    </ModalBody>
   </ModalContent>
  </Modal>
 );
};

export default TransmissionModal;
