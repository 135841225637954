import React, { useState } from "react";
import {
 Modal,
 ModalOverlay,
 ModalContent,
 ModalHeader,
 ModalCloseButton,
 ModalBody,
 Input,
 Grid,
 Box,
 Text,
 Flex,
 Button,
} from "@chakra-ui/react";

const FuelModal = ({
 isOpen,
 onClose,
 fuels = [],
 onSelect,
 onFuelSelectComplete,
}) => {
 const [selectedFuel, setSelectedFuel] = useState(null);
 const [step, setStep] = useState(1); // Step 1: Fuel Selection, Step 2: User Details
 const [name, setName] = useState("");
 const [mobile, setMobile] = useState("");
 const [searchQuery, setSearchQuery] = useState("");

 // Filter fuels based on the search query
 const filteredFuels = fuels?.filter((fuel) =>
  fuel?.toLowerCase().includes(searchQuery.toLowerCase())
 );

 // Handle fuel selection
 const handleSelectFuel = (fuel) => {
  setSelectedFuel(fuel);
  setStep(2); // Move to Step 2: User Details
 };

 // Handle user details submission
 const handleDetailsSubmit = () => {
  if (!name || !/^\d{10}$/.test(mobile)) {
   alert("Please provide a valid name and 10-digit mobile number.");
   return;
  }

  // Call the parent onSelect function with the fuel type
  onSelect(selectedFuel);

  // Close the modal
  onClose();

  // Delay the scrolling action after modal closes
  setTimeout(() => {
   if (onFuelSelectComplete) {
    onFuelSelectComplete(); // Trigger the scroll callback after modal close
   }
  }, 100);
 };

 return (
  <Modal isOpen={isOpen} onClose={onClose} isCentered>
   <ModalOverlay />
   <ModalContent width="700px" maxWidth="700px" maxHeight="80vh" mx="auto">
    {step === 1 ? (
     <>
      <ModalHeader>Select Fuel Type</ModalHeader>
      <ModalCloseButton />
      <Flex
       position="sticky"
       top="0"
       bg="white"
       zIndex="1000"
       p={4}
       borderBottom="1px solid #eaeaea"
      >
       <Input
        placeholder="Search Fuel Type"
        mb={4}
        value={searchQuery}
        onChange={(e) => setSearchQuery(e.target.value)}
        border="1px solid #ddd"
        borderRadius="md"
        padding="10px"
        fontSize="16px"
        _focus={{
         borderColor: "blue.500",
         boxShadow: "0 0 0 1px blue.500",
        }}
        _hover={{ borderColor: "blue.300" }}
       />
      </Flex>
      <ModalBody
       overflowY="auto"
       maxHeight="60vh"
       pt="0"
       sx={{
        "::-webkit-scrollbar": { display: "none" },
        "-ms-overflow-style": "none",
        "scrollbar-width": "none",
       }}
      >
       <Grid templateColumns="repeat(auto-fit, minmax(100px, 1fr))" gap={6}>
        {filteredFuels.length > 0 ? (
         filteredFuels.map((fuel, index) => (
          <Box
           key={index}
           textAlign="center"
           cursor="pointer"
           onClick={() => handleSelectFuel(fuel)}
           _hover={{ bg: "gray.100", borderRadius: "md" }}
           p={2}
          >
           <Text mt={2} fontWeight="bold">
            {fuel}
           </Text>
          </Box>
         ))
        ) : (
         <Text>No results found</Text>
        )}
       </Grid>
      </ModalBody>
     </>
    ) : (
     <>
      <ModalHeader>Enter Your Details</ModalHeader>
      <ModalCloseButton />
      <ModalBody>
       <Flex direction="column" gap={4}>
        <Text fontSize="lg" fontWeight="bold" mb={2}>
         Selected Fuel: {selectedFuel}
        </Text>
        <Input
         placeholder="Enter your name"
         value={name}
         onChange={(e) => setName(e.target.value)}
         border="1px solid #ddd"
         borderRadius="md"
         padding="10px"
         fontSize="16px"
         _focus={{
          borderColor: "blue.500",
          boxShadow: "0 0 0 1px blue.500",
         }}
         _hover={{ borderColor: "blue.300" }}
        />
        <Input
         placeholder="Enter your 10-digit mobile number"
         value={mobile}
         onChange={(e) => setMobile(e.target.value)}
         border="1px solid #ddd"
         borderRadius="md"
         padding="10px"
         fontSize="16px"
         type="tel"
         _focus={{
          borderColor: "blue.500",
          boxShadow: "0 0 0 1px blue.500",
         }}
         _hover={{ borderColor: "blue.300" }}
        />
        <Button colorScheme="blue" onClick={handleDetailsSubmit}>
         Submit
        </Button>
       </Flex>
      </ModalBody>
     </>
    )}
   </ModalContent>
  </Modal>
 );
};

export default FuelModal;

// import React, { useState } from "react";
// import {
//  Modal,
//  ModalOverlay,
//  ModalContent,
//  ModalHeader,
//  ModalCloseButton,
//  ModalBody,
//  Input,
//  Grid,
//  Box,
//  Text,
//  Flex,
// } from "@chakra-ui/react";

// const FuelModal = ({
//  isOpen,
//  onClose,
//  fuels = [],
//  onSelect,
//  onFuelSelectComplete,
// }) => {
//  const [searchQuery, setSearchQuery] = useState("");

//  // Filter fuels based on the search query
//  const filteredFuels = fuels?.filter((fuel) =>
//   fuel?.toLowerCase().includes(searchQuery.toLowerCase())
//  );

//  // Handle fuel selection
//  const handleSelect = (fuel) => {
//   onSelect(fuel);
//   onClose();

//   // Delay the scrolling action after modal closes
//   setTimeout(() => {
//    if (onFuelSelectComplete) {
//     onFuelSelectComplete(); // Trigger the scroll callback after modal close
//    }
//   }, 100); // Slight delay to allow the modal to close before scrolling
//  };

//  return (
//   <Modal isOpen={isOpen} onClose={onClose} isCentered>
//    <ModalOverlay />
//    <ModalContent width="700px" maxWidth="700px" maxHeight="80vh" mx="auto">
//     <ModalHeader>Select Fuel Type</ModalHeader>
//     <ModalCloseButton />

//     {/* Search Input */}
//     <Flex
//      position="sticky"
//      top="0"
//      bg="white"
//      zIndex="1000"
//      p={4}
//      borderBottom="1px solid #eaeaea"
//     >
//      <Input
//       placeholder="Search Fuel Type"
//       mb={4}
//       value={searchQuery}
//       onChange={(e) => setSearchQuery(e.target.value)}
//       border="1px solid #ddd"
//       borderRadius="md"
//       padding="10px"
//       fontSize="16px"
//       _focus={{ borderColor: "blue.500", boxShadow: "0 0 0 1px blue.500" }}
//       _hover={{ borderColor: "blue.300" }}
//      />
//     </Flex>

//     <ModalBody
//      overflowY="auto"
//      maxHeight="60vh"
//      pt="0"
//      sx={{
//       "::-webkit-scrollbar": { display: "none" },
//       "-ms-overflow-style": "none",
//       "scrollbar-width": "none",
//      }}
//     >
//      <Grid templateColumns="repeat(auto-fit, minmax(100px, 1fr))" gap={6}>
//       {filteredFuels.length > 0 ? (
//        filteredFuels.map((fuel, index) => (
//         <Box
//          key={index}
//          textAlign="center"
//          cursor="pointer"
//          onClick={() => handleSelect(fuel)}
//          _hover={{ bg: "gray.100", borderRadius: "md" }}
//          p={2}
//         >
//          <Text mt={2} fontWeight="bold">
//           {fuel}
//          </Text>
//         </Box>
//        ))
//       ) : (
//        <Text>No results found</Text>
//       )}
//      </Grid>
//     </ModalBody>
//    </ModalContent>
//   </Modal>
//  );
// };

// export default FuelModal;

// // import React, { useState } from "react";
// // import {
// //  Modal,
// //  ModalOverlay,
// //  ModalContent,
// //  ModalHeader,
// //  ModalCloseButton,
// //  ModalBody,
// //  Input,
// //  Grid,
// //  Box,
// //  Text,
// //  Flex,
// // } from "@chakra-ui/react";

// // const FuelModal = ({ isOpen, onClose, fuels, onSelect }) => {
// //  const [searchQuery, setSearchQuery] = useState("");

// //  // Filter fuels based on the search query
// //  const filteredFuels = fuels?.filter((fuel) =>
// //   fuel?.toLowerCase().includes(searchQuery.toLowerCase())
// //  );

// //  const handleSelect = (fuel) => {
// //   onSelect(fuel);
// //   onClose();
// //  };

// //  return (
// //   <Modal isOpen={isOpen} onClose={onClose} isCentered>
// //    <ModalOverlay />
// //    <ModalContent width="700px" maxWidth="700px" maxHeight="80vh" mx="auto">
// //     <ModalHeader>Select Fuel Type</ModalHeader>
// //     <ModalCloseButton />

// //     {/* Search Input */}
// //     <Flex
// //      position="sticky"
// //      top="0"
// //      bg="white"
// //      zIndex="1000"
// //      p={4}
// //      borderBottom="1px solid #eaeaea"
// //     >
// //      <Input
// //       placeholder="Search Fuel Type"
// //       mb={4}
// //       value={searchQuery}
// //       onChange={(e) => setSearchQuery(e.target.value)}
// //       border="1px solid #ddd"
// //       borderRadius="md"
// //       padding="10px"
// //       fontSize="16px"
// //       _focus={{ borderColor: "blue.500", boxShadow: "0 0 0 1px blue.500" }}
// //       _hover={{ borderColor: "blue.300" }}
// //      />
// //     </Flex>

// //     <ModalBody
// //      overflowY="auto"
// //      maxHeight="60vh"
// //      pt="0"
// //      sx={{
// //       "::-webkit-scrollbar": { display: "none" },
// //       "-ms-overflow-style": "none",
// //       "scrollbar-width": "none",
// //      }}
// //     >
// //      <Grid templateColumns="repeat(auto-fit, minmax(100px, 1fr))" gap={6}>
// //       {filteredFuels?.length > 0 ? (
// //        filteredFuels.map((fuel, index) => (
// //         <Box
// //          key={index}
// //          textAlign="center"
// //          cursor="pointer"
// //          onClick={() => handleSelect(fuel)}
// //          _hover={{ bg: "gray.100", borderRadius: "md" }}
// //          p={2}
// //         >
// //          <Text mt={2} fontWeight="bold">
// //           {fuel}
// //          </Text>
// //         </Box>
// //        ))
// //       ) : (
// //        <Text>No results found</Text>
// //       )}
// //      </Grid>
// //     </ModalBody>
// //    </ModalContent>
// //   </Modal>
// //  );
// // };

// // export default FuelModal;
