import { useEffect, useState } from "react";

export default function useScreenSize() {
 const [screenSize, setScreenSize] = useState(getScreenSize());

 useEffect(() => {
  const handleResize = () => setScreenSize(getScreenSize());
  window.addEventListener("resize", handleResize);

  return () => window.removeEventListener("resize", handleResize);
 }, []);

 function getScreenSize() {
  const width = window.innerWidth;
  if (width <= 576) return "xs"; // Extra Small
  if (width <= 768) return "sm"; // Small
  if (width <= 992) return "md"; // Medium
  if (width <= 1200) return "lg"; // Large
  return "xl"; // Extra Large
 }

 return screenSize;
}
