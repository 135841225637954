import React from "react";
import { Routes, Route, Navigate } from "react-router-dom";
import Home from "../Components/pages/Home";
import About from "../Components/pages/About";
import Blog from "../Components/pages/Blog";
import BlogDetails from "../Components/pages/BlogDetails";
import CarDetails from "../Components/pages/CarDetails";
import CarListing from "../Components/pages/CarListing";
import NotFound from "../Components/pages/NotFound";
import Services from "../Components/pages/Services";
import Contact from "../Components/pages/Contact";
import Franchisee from "../Components/pages/Franchisee";
import Privacy from "../Components/pages/Privacy";
import Term from "../Components/pages/Term";
import Refund from "../Components/pages/Refund";
import Products from "../Components/pages/Products";
import PaymentSuccess from "../Components/pages/PaymentSuccess";
import PaymentFailure from "../Components/pages/PaymentFailure";
import PaymentStatus from "../Components/pages/PaymentStatus";
import RegisterComponent from "../Components/franchise/RegisterComponent";
// import PaymentStatus from "../Components/pages/PaymentStatus"; // Import the new component

const Routers = () => {
 return (
  <Routes>
   {/* <Route path="/" element={<Navigate to="/home" />} /> */}
   <Route path="/" element={<Home />} />
   <Route path="/about" element={<About />} />
   <Route path="/cars" element={<CarListing />} />
   <Route path="/cars/:slug" element={<CarDetails />} />
   <Route path="/blogs" element={<Blog />} />
   <Route path="/blogs/:slug" element={<BlogDetails />} />
   <Route path="/service" element={<Services />} />
   <Route path="/product" element={<Products />} />
   <Route path="/contact" element={<Contact />} />
   <Route path="/register" element={<RegisterComponent />} />
   <Route path="/Franchisee" element={<Franchisee />} />
   <Route path="/Privacy" element={<Privacy />} />
   <Route path="/Term" element={<Term />} />
   <Route path="/Refund" element={<Refund />} />
   <Route path="/status" element={<PaymentStatus />} /> {/* Updated Route */}
   <Route path="/payment-success" element={<PaymentSuccess />} />
   <Route path="/payment-failure" element={<PaymentFailure />} />
   <Route path="*" element={<NotFound />} />
  </Routes>
 );
};

export default Routers;
