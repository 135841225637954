import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

// Base URL from environment variables for flexibility
const API_BASE_URL =
 process.env.REACT_APP_API_URL || "https://app-api.carexpert.org.in";

// Async thunk to create a form entry using FormData for file uploads
export const createFormEntry = createAsyncThunk(
 "form/createFormEntry",
 async (formData, { rejectWithValue }) => {
  try {
   const response = await axios.post(
    `${API_BASE_URL}/api/franchise/create`,
    formData,
    {
     headers: {
      "Content-Type": "multipart/form-data",
     },
    }
   );
   return response.data;
  } catch (error) {
   // Return a custom error message from the API or network error
   return rejectWithValue(
    error.response
     ? error.response.data
     : { message: "Network Error", code: "NETWORK_ERROR" }
   );
  }
 }
);

const formSlice = createSlice({
 name: "form",
 initialState: {
  formEntry: null,
  loading: false,
  success: false,
  error: null,
 },
 reducers: {
  resetFormState: (state) => {
   state.formEntry = null;
   state.loading = false;
   state.success = false;
   state.error = null;
  },
 },
 extraReducers: (builder) => {
  builder
   .addCase(createFormEntry.pending, (state) => {
    state.loading = true;
    state.error = null;
    state.success = false;
   })
   .addCase(createFormEntry.fulfilled, (state, action) => {
    state.loading = false;
    state.formEntry = action.payload;
    state.success = true;
    state.error = null;
   })
   .addCase(createFormEntry.rejected, (state, action) => {
    state.loading = false;
    state.error = action.payload || "An error occurred";
    state.success = false;
   });
 },
});

export const { resetFormState } = formSlice.actions;

export default formSlice.reducer;
