import React from 'react';
import { Box, Heading, Text, ListItem, UnorderedList } from '@chakra-ui/react';

const PrivacyPolicy = () => {
  return (
    <Box p={8} maxW="800px" mx="auto">
      <Heading as="h1" size="xl" mb={6}>
        Privacy Policy
      </Heading>

      <Text fontSize="lg" mb={4}>
        <strong>Effective Date:</strong> 20/10/2022
      </Text>

      <Text fontSize="lg" mb={4}>
        At Carexpert, we respect your privacy and are committed to protecting the personal information you share with us. This Privacy Policy explains how we collect, use, and safeguard your information when you use our website and mobile application (referred to collectively as the "Services"). By using our Services, you agree to the collection and use of information in accordance with this Privacy Policy.
      </Text>

       <Heading as="h2" size="md" mt={8} mb={4} color={'#26376F'}>
        1. Information We Collect
      </Heading>

      <Text fontSize="lg" mb={4}>
        We collect various types of information to provide and improve our Services, including:
      </Text>

      <Heading as="h3" size="md" mt={4} mb={2}>
        a. Personal Information
      </Heading>
      <Text fontSize="lg" mb={4}>
        When you register for our Services or book a service appointment, we may collect personal information such as:
      </Text>
      <UnorderedList pl={6}>
        <ListItem>Name</ListItem>
        <ListItem>Email address</ListItem>
        <ListItem>Phone number</ListItem>
        <ListItem>Vehicle details (e.g., make, model, year)</ListItem>
        <ListItem>Payment information (if applicable)</ListItem>
      </UnorderedList>

      <Heading as="h3" size="md" mt={4} mb={2}>
        b. Location Data
      </Heading>
      <Text fontSize="lg" mb={4}>
        In order to provide our services, which include car servicing and car cleaning at your doorstep, we collect and use your device’s location data. This data is necessary to:
      </Text>
      <UnorderedList pl={6}>
        <ListItem>Identify your current location for service delivery</ListItem>
        <ListItem>Estimate service times based on distance</ListItem>
        <ListItem>Assign service providers to your location</ListItem>
      </UnorderedList>
      <Text fontSize="lg" mb={4}>
        You can enable or disable location services at any time through your device settings. However, disabling location access may impact our ability to provide doorstep services.
      </Text>

      <Heading as="h3" size="md" mt={4} mb={2}>
        c. Log Data
      </Heading>
      <Text fontSize="lg" mb={4}>
        We collect log information automatically when you interact with our website or app, such as:
      </Text>
      <UnorderedList pl={6}>
        <ListItem>IP address</ListItem>
        <ListItem>Browser type</ListItem>
        <ListItem>Device information</ListItem>
        <ListItem>Pages visited</ListItem>
        <ListItem>Time and date of visit</ListItem>
        <ListItem>Diagnostic data</ListItem>
      </UnorderedList>

       <Heading as="h2" size="md" mt={8} mb={4} color={'#26376F'}>
        2. How We Use Your Information
      </Heading>
      <Text fontSize="lg" mb={4}>
        We use the information we collect for the following purposes:
      </Text>
      <UnorderedList pl={6}>
        <ListItem>To provide, operate, and maintain our Services</ListItem>
        <ListItem>To process your bookings and communicate service updates</ListItem>
        <ListItem>To personalize and improve your user experience</ListItem>
        <ListItem>To provide customer support</ListItem>
        <ListItem>To comply with legal obligations</ListItem>
      </UnorderedList>

       <Heading as="h2" size="md" mt={8} mb={4} color={'#26376F'}>
        3. Sharing Your Information
      </Heading>
      <Text fontSize="lg" mb={4}>
        We may share your personal information with third parties in the following situations:
      </Text>
      <UnorderedList pl={6}>
        <ListItem>
          <strong>Service Providers:</strong> We may share your information with third-party service providers who help us operate our business and provide the Services (e.g., payment processors, technicians, and customer service providers).
        </ListItem>
        <ListItem>
          <strong>Legal Requirements:</strong> We may disclose your information if required by law or to protect the rights, safety, or property of Carexpert, our users, or others.
        </ListItem>
        <ListItem>
          <strong>Business Transfers:</strong> In the event of a merger, acquisition, or asset sale, your information may be transferred as part of the transaction.
        </ListItem>
      </UnorderedList>

       <Heading as="h2" size="md" mt={8} mb={4} color={'#26376F'}>
        4. Data Security
      </Heading>
      <Text fontSize="lg" mb={4}>
        We take the security of your personal information seriously and implement industry-standard measures to protect it from unauthorized access, alteration, disclosure, or destruction. However, no method of transmission over the internet or electronic storage is 100% secure, and we cannot guarantee its absolute security.
      </Text>

       <Heading as="h2" size="md" mt={8} mb={4} color={'#26376F'}>
        5. Your Choices
      </Heading>
      <Text fontSize="lg" mb={4}>
        You have the following choices regarding your personal information:
      </Text>
      <UnorderedList pl={6}>
        <ListItem>
          <strong>Access and Update:</strong> You can access and update your personal information through your account settings or by contacting us.
        </ListItem>
        <ListItem>
          <strong>Location Data:</strong> You can enable or disable location services in your device settings. However, please note that certain features of our Services may not function properly if location access is disabled.
        </ListItem>
        <ListItem>
          <strong>Marketing Communications:</strong> You can opt-out of receiving marketing communications by following the unsubscribe instructions in our emails or contacting us directly.
        </ListItem>
      </UnorderedList>

       <Heading as="h2" size="md" mt={8} mb={4} color={'#26376F'}>
        6. Cookies and Tracking Technologies
      </Heading>
      <Text fontSize="lg" mb={4}>
        We use cookies and similar tracking technologies to collect information about your interactions with our website and app. You can manage cookie preferences through your browser settings, though disabling cookies may affect the functionality of our Services.
      </Text>

       <Heading as="h2" size="md" mt={8} mb={4} color={'#26376F'}>
        7. Children’s Privacy
      </Heading>
      <Text fontSize="lg" mb={4}>
        Our Services are not directed to individuals under the age of 13, and we do not knowingly collect personal information from children. If we become aware that we have collected personal information from a child under 13, we will take steps to delete it.
      </Text>

      <Heading as="h2" size="md" mt={8} mb={4} color={'#26376F'}>
        8. Changes to This Privacy Policy
      </Heading>
      <Text fontSize="lg" mb={4}>
        We may update this Privacy Policy from time to time. When we do, we will notify you by updating the effective date at the top of this policy. We encourage you to review this policy periodically for any changes.
      </Text>

      <Heading as="h2" size="md" mt={8} mb={4} color={'#26376F'}>
        9. Contact Us
      </Heading>
      <Text fontSize="lg" mb={4}>
        If you have any questions about this Privacy Policy, please contact us at:
      </Text>
      <UnorderedList pl={6}>
        <ListItem>
          <strong>Email:</strong> care@carexpert.org.in
        </ListItem>
      
      </UnorderedList>
    </Box>
  );
};

export default PrivacyPolicy;
