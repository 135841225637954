import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

const defaultCategoryName = "Periodic Services "; // Default category
const defaultSubCategoryName = "Scheduled Packages"; // Default subcategory

export const fetchCategories = createAsyncThunk(
 "services/fetchCategories",
 async (_, { dispatch }) => {
  const response = await axios.get(
   "https://app-api.carexpert.org.in/api/categories/all"
  );
  const categories = response.data;

  // Auto-select default category
  const defaultCategory = categories.find(
   (category) => category.categoryName === defaultCategoryName
  );

  if (defaultCategory) {
   dispatch(selectCategory(defaultCategory._id));
   dispatch(fetchSubCategories(defaultCategory._id));
  }

  return categories;
 }
);

export const fetchSubCategories = createAsyncThunk(
 "services/fetchSubCategories",
 async (categoryId, { dispatch }) => {
  const response = await axios.get(
   "https://app-api.carexpert.org.in/api/subcategories/all"
  );
  const filteredSubCategories = response.data.filter(
   (subCat) => subCat.categoryId._id === categoryId
  );

  // Auto-select default subcategory if available
  const defaultSubCategory = filteredSubCategories.find(
   (subCategory) => subCategory.subCategoryName === defaultSubCategoryName
  );

  if (defaultSubCategory) {
   dispatch(selectSubCategory(defaultSubCategory._id));
   dispatch(fetchProducts(defaultSubCategory._id));
  }

  return { categoryId, subCategories: filteredSubCategories };
 }
);

export const fetchProducts = createAsyncThunk(
 "services/fetchProducts",
 async (subCategoryId) => {
  const response = await axios.get(
   "https://app-api.carexpert.org.in/api/products"
  );
  const filteredProducts = response.data.filter(
   (product) => product.subCategoryId._id === subCategoryId
  );

  return { subCategoryId, products: filteredProducts };
 }
);

const servicesSlice = createSlice({
 name: "services",
 initialState: {
  categories: [],
  subCategories: {},
  products: {},
  loading: false,
  error: null,
  selectedCategory: null,
  selectedSubCategory: null,
 },
 reducers: {
  selectCategory: (state, action) => {
   state.selectedCategory = action.payload;
  },
  selectSubCategory: (state, action) => {
   state.selectedSubCategory = action.payload;
  },
 },
 extraReducers: (builder) => {
  builder
   .addCase(fetchCategories.pending, (state) => {
    state.loading = true;
   })
   .addCase(fetchCategories.fulfilled, (state, action) => {
    state.loading = false;
    state.categories = action.payload;
   })
   .addCase(fetchCategories.rejected, (state, action) => {
    state.loading = false;
    state.error = action.error.message;
   })
   .addCase(fetchSubCategories.pending, (state) => {
    state.loading = true;
   })
   .addCase(fetchSubCategories.fulfilled, (state, action) => {
    const { categoryId, subCategories } = action.payload;
    state.loading = false;
    state.subCategories[categoryId] = subCategories;
   })
   .addCase(fetchSubCategories.rejected, (state, action) => {
    state.loading = false;
    state.error = action.error.message;
   })
   .addCase(fetchProducts.pending, (state) => {
    state.loading = true;
   })
   .addCase(fetchProducts.fulfilled, (state, action) => {
    const { subCategoryId, products } = action.payload;
    state.loading = false;
    state.products[subCategoryId] = products;
   })
   .addCase(fetchProducts.rejected, (state, action) => {
    state.loading = false;
    state.error = action.error.message;
   });
 },
});

export const { selectCategory, selectSubCategory } = servicesSlice.actions;
export default servicesSlice.reducer;
