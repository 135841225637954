import React from "react";
import { Box, Grid, Flex, Text, Container } from "@chakra-ui/react";
import Slider from "react-slick";

// Import slick carousel CSS
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

// Sample data for the carousel slides
const slides = [
 {
  id: 1,
  title: "Are You a Workshop Owner?",
  description:
   "Join India’s fastest growing car service network and get more customers with seamless customer support and inventory management.",
  image: "/car.jpg", // Replace with actual image path
 },
 {
  id: 2,
  title: "Need Spares Retailer?",
  description:
   "Grow your business by joining our platform and offering a wide range of spares to our growing customer base.",
  image: "/about_banner.jpg", // Replace with actual image path
 },
 {
  id: 3,
  title: "Expand Your Workshop?",
  description:
   "Become a partner and take advantage of our advanced tools and services to expand your workshop's reach.",
  image: "/img4.jpeg", // Replace with actual image path
 },
 {
  id: 4,
  title: "Expand Your Workshop?",
  description:
   "Become a partner and take advantage of our advanced tools and services to expand your workshop's reach.",
  image: "/franchisee_banner.png", // Replace with actual image path
 },
 {
  id: 5,
  title: "Expand Your Workshop?",
  description:
   "Become a partner and take advantage of our advanced tools and services to expand your workshop's reach.",
  image: "/carservice.jpg", // Replace with actual image path
 },
 {
  id: 6,
  title: "Expand Your Workshop?",
  description:
   "Become a partner and take advantage of our advanced tools and services to expand your workshop's reach.",
  image: "/carservicing.jpg", // Replace with actual image path
 },
];

// Main FormBanner Component
const FormBanner = () => {
 // Settings for the slick carousel
 const settings = {
  dots: true,
  infinite: true,
  speed: 500,
  slidesToShow: 1,
  slidesToScroll: 1,
  autoplay: true,
  autoplaySpeed: 3000,
 };

 return (
  <Flex bg="#091018" justify="center" align="center" w="100%">
   <Box w="100%">
    <Slider {...settings}>
     {slides.map((slide) => (
      <Flex
       key={slide.id}
       bgImage={`url(${slide.image})`}
       bgSize="cover"
       bgPosition="center"
       height="65vh"
       align="center"
       justify="center"
       position="relative"
       color="white"
      >
       <Grid
        templateColumns={{ base: "1fr", sm: "2fr 1fr" }}
        height="100%"
        width="100%"
       >
        {/* Image Section */}
        <Box
         display={{ base: "none", sm: "block" }}
         bgImage={`url(${slide.image})`}
         bgSize="cover"
         bgPosition="center"
        />

        {/* Text Section */}
        <Flex bg="rgba(0, 0, 0, 0.5)" p={6} align="center" justify="center">
         <Box textAlign="center">
          <Text fontSize="3xl" fontWeight="bold" mb={4}>
           {slide.title}
          </Text>
          <Text fontSize="lg">{slide.description}</Text>
         </Box>
        </Flex>
       </Grid>
      </Flex>
     ))}
    </Slider>
   </Box>
  </Flex>
 );
};

export default FormBanner;
