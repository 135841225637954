import React, { useState } from "react";
import {
 Modal,
 ModalOverlay,
 ModalContent,
 ModalHeader,
 ModalCloseButton,
 ModalBody,
 Input,
 Grid,
 Box,
 Image,
 Text,
 Flex,
} from "@chakra-ui/react";

const BrandModal = ({ isOpen, onClose, brands, onSelect }) => {
 const [searchQuery, setSearchQuery] = useState("");

 // Filter brands based on the search query
 const filteredBrands = brands?.filter((brand) =>
  brand?.title?.toLowerCase().includes(searchQuery.toLowerCase())
 );

 const handleSelect = (brand) => {
  onSelect(brand);
  onClose();
 };

 return (
  <Modal isOpen={isOpen} onClose={onClose} isCentered>
   <ModalOverlay />
   <ModalContent width="700px" maxWidth="700px" maxHeight="80vh" mx="auto">
    <ModalHeader>Select Brand</ModalHeader>
    <ModalCloseButton />

    {/* Search Input */}
    <Flex
     position="sticky"
     top="0"
     bg="white"
     zIndex="1000"
     p={4}
     borderBottom="1px solid #eaeaea"
    >
     <Input
      placeholder="Search Brand"
      mb={4}
      value={searchQuery}
      onChange={(e) => setSearchQuery(e.target.value)}
      border="1px solid #ddd"
      borderRadius="md"
      padding="10px"
      fontSize="16px"
      _focus={{ borderColor: "blue.500", boxShadow: "0 0 0 1px blue.500" }}
      _hover={{ borderColor: "blue.300" }}
     />
    </Flex>

    <ModalBody
     overflowY="auto"
     maxHeight="60vh"
     pt="0"
     sx={{
      "::-webkit-scrollbar": { display: "none" },
      "-ms-overflow-style": "none",
      "scrollbar-width": "none",
     }}
    >
     <Grid templateColumns="repeat(auto-fit, minmax(100px, 1fr))" gap={6}>
      {filteredBrands?.length > 0 ? (
       filteredBrands.map((brand) => (
        <Box
         key={brand._id}
         textAlign="center"
         cursor="pointer"
         onClick={() => handleSelect(brand)}
         _hover={{ bg: "gray.100", borderRadius: "md" }}
         p={2}
        >
         <Image src={brand.logo} alt={brand.title} boxSize="50px" mx="auto" />
         <Text mt={2} fontWeight="bold">
          {brand.title}
         </Text>
        </Box>
       ))
      ) : (
       <Text>No results found</Text>
      )}
     </Grid>
    </ModalBody>
   </ModalContent>
  </Modal>
 );
};

export default BrandModal;
