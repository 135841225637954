// src/components/FranchiseDetails.js

import React from "react";
import {
 Box,
 Text,
 SimpleGrid,
 Card,
 CardHeader,
 CardBody,
} from "@chakra-ui/react";

const FranchiseDetails = ({ data }) => {
 return (
  <Box
   marginBottom={8}
   backgroundColor="white"
   padding={4}
   borderRadius="md"
   boxShadow="md"
  >
   {/* Company Information */}
   <Card marginBottom={8}>
    <CardHeader>
     <Text fontSize="2xl" fontWeight="bold">
      About Company
     </Text>
    </CardHeader>
    <CardBody>
     <Text fontSize="md">
      {data.companyName} is a {data.companyType} company specializing in{" "}
      {data.franchiseType} services. Located at {data.companyAddress}, we focus
      on delivering quality service with a quality parameter above{" "}
      {data.qualityParameter}.
     </Text>
    </CardBody>
   </Card>

   {/* Personal Information */}
   <Card marginBottom={8}>
    <CardHeader>
     <Text fontSize="2xl" fontWeight="bold">
      Personal Information
     </Text>
    </CardHeader>
    <CardBody>
     <SimpleGrid columns={{ base: 1, md: 2 }} spacing={4}>
      <Box>
       <Text>
        <strong>Name:</strong> {data.firstName} {data.lastName}
       </Text>
       <Text>
        <strong>Date of Birth:</strong>{" "}
        {new Date(data.dob).toLocaleDateString()}
       </Text>
       <Text>
        <strong>Gender:</strong> {data.gender}
       </Text>
      </Box>
      <Box>
       <Text>
        <strong>Contact Number:</strong> {data.contactNumber}
       </Text>
       <Text>
        <strong>Email:</strong> {data.email}
       </Text>
       <Text>
        <strong>Address:</strong> {data.address}
       </Text>
      </Box>
     </SimpleGrid>
    </CardBody>
   </Card>

   {/* Business Information */}
   <Card marginBottom={8}>
    <CardHeader>
     <Text fontSize="2xl" fontWeight="bold">
      Business Information
     </Text>
    </CardHeader>
    <CardBody>
     <SimpleGrid columns={{ base: 1, md: 2 }} spacing={4}>
      <Box>
       <Text>
        <strong>Company Name:</strong> {data.companyName}
       </Text>
       <Text>
        <strong>Company Type:</strong> {data.companyType}
       </Text>
       <Text>
        <strong>Business Contact:</strong> {data.businessContactNumber}
       </Text>
      </Box>
      <Box>
       <Text>
        <strong>Document Type:</strong> {data.businessDocumentType}
       </Text>
       <Text>
        <strong>Franchise Type:</strong> {data.franchiseType}
       </Text>
       <Text>
        <strong>Investment Amount:</strong> {data.investmentAmount}
       </Text>
      </Box>
     </SimpleGrid>
    </CardBody>
   </Card>

   {/* Financial Information */}
   <Card marginBottom={8}>
    <CardHeader>
     <Text fontSize="2xl" fontWeight="bold">
      Financial Information
     </Text>
    </CardHeader>
    <CardBody>
     <SimpleGrid columns={{ base: 1, md: 2 }} spacing={4}>
      <Box>
       <Text>
        <strong>Account Name:</strong> {data.accountName}
       </Text>
       <Text>
        <strong>Account Number:</strong> {data.accountNumber}
       </Text>
       <Text>
        <strong>IFSC Code:</strong> {data.ifscCode}
       </Text>
      </Box>
      <Box>
       <Text>
        <strong>Branch Name:</strong> {data.branchName}
       </Text>
       <Text>
        <strong>Bank Name:</strong> {data.bankName}
       </Text>
       <Text>
        <strong>Registration Amount:</strong> {data.registrationAmount}
       </Text>
      </Box>
     </SimpleGrid>
    </CardBody>
   </Card>

   {/* Footer */}
   <Box textAlign="center" padding={4} backgroundColor="gray.300" marginTop={8}>
    <Text fontSize="sm">
     &copy; {new Date().getFullYear()} {data.companyName}. All rights reserved.
    </Text>
   </Box>
  </Box>
 );
};

export default FranchiseDetails;
