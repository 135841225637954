import React from "react";
import {
 Box,
 Container,
 Text,
 Button,
 Flex,
 Image,
 Divider,
 Link,
} from "@chakra-ui/react";
import { useNavigate } from "react-router-dom";

const PaymentFailure = ({ service, carModel }) => {
 const navigate = useNavigate();

 return (
  <Box bg="#f0f0f0" py={10} px={5}>
   <Container maxW="lg" bg="white" p={6} borderRadius="md" boxShadow="md">
    {/* Failure Message */}
    <Flex direction="column" align="center" mb={4}>
     <Image
      src="https://example.com/failure-icon.png" // Replace with your failure icon image
      alt="Failure"
      boxSize="100px"
      mb={4}
     />
     <Text fontSize="2xl" fontWeight="bold" color="red.500">
      Payment Failed!
     </Text>
     <Text fontSize="md" textAlign="center" mt={2} mb={6}>
      Unfortunately, your payment for {service} ({carModel}) could not be
      processed.
     </Text>
    </Flex>

    {/* Retry Information */}
    <Text textAlign="center" fontSize="lg" fontWeight="bold" mb={4}>
     Please try again.
    </Text>
    <Text textAlign="center" mb={6}>
     You can retry the payment or contact our support team for assistance.
    </Text>

    {/* Action Buttons */}
    <Flex justify="center" mb={6}>
     <Button colorScheme="blue" onClick={() => navigate("/services")} mr={4}>
      Retry Payment
     </Button>
     <Button variant="outline" onClick={() => navigate("/")}>
      Go to Home
     </Button>
    </Flex>

    {/* Support Information */}
    <Divider mb={6} />
    <Text textAlign="center" fontSize="sm">
     Need help?{" "}
     <Link href="mailto:support@carexpert.org.in" color="blue.500">
      Contact our support team
     </Link>
     .
    </Text>
   </Container>
  </Box>
 );
};

export default PaymentFailure;
