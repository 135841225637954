import React from "react";
import {
 Box,
 Heading,
 Text,
 Avatar,
 Grid,
 GridItem,
 Container,
 useColorModeValue,
} from "@chakra-ui/react";
import Slider from "react-slick";
import { FaQuoteLeft } from "react-icons/fa";

// Import slick carousel CSS
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

// Sample testimonial data
const testimonials = [
 {
  id: 1,
  name: "Merjin Jose",
  business: "Owner - Car-Expert Shalom Multi Car Care",
  testimonial:
   "The kind of technical assistance Car-Expert gives to its partner workshops is amazing. They even created an app which made the management of the garage at the ease of the clicks.",
  avatar: "D",
 },
 {
  id: 2,
  name: "Ajay Shokeen",
  business: "Owner - Car-Expert Insta Car Care",
  testimonial:
   "My garage was going at a sustainable pace. But there wasn’t much growth in the business, but a partnership with Car-Expert proved to be really beneficial for me.",
  avatar: "",
 },
 {
  id: 3,
  name: "Karan Bhutani",
  business: "Owner - Car-Expert Keshav Automobiles",
  testimonial:
   "Good marketing means good business, this is something I learned from Car-Expert. My workshop now gained brand status in the area.",
  avatar: "D",
 },
 {
  id: 4,
  name: "Sohan Kumar",
  business: "Owner - Car-Expert Shine Auto",
  testimonial:
   "Working with Car-Expert has helped my business grow rapidly with their amazing service support and marketing strategies.",
  avatar: "S",
 },
 {
  id: 5,
  name: "Ravi Sharma",
  business: "Owner - Car-Expert Speed Motors",
  testimonial:
   "The tools and customer management provided by Car-Expert have made my business efficient and smooth. Highly recommended!",
  avatar: "R",
 },
];

const FormTestimonials = () => {
 // Carousel settings to show 3 cards in a row with auto-scrolling
 const settings = {
  dots: true,
  infinite: true,
  speed: 500,
  slidesToShow: 3,
  slidesToScroll: 1,
  autoplay: true,
  autoplaySpeed: 3000,
  arrows: true,
  responsive: [
   {
    breakpoint: 1024, // Adjusts for tablets and large screens
    settings: {
     slidesToShow: 2,
     slidesToScroll: 1,
     infinite: true,
     dots: true,
    },
   },
   {
    breakpoint: 600, // Adjusts for mobile phones
    settings: {
     slidesToShow: 1,
     slidesToScroll: 1,
    },
   },
  ],
 };

 // Colors based on color mode (light/dark)
 const bgColor = useColorModeValue("white", "gray.700");
 const textColor = useColorModeValue("gray.500", "gray.200");
 const quoteIconColor = useColorModeValue("green.500", "green.300");

 return (
  <Container maxW="container.xl" py={10}>
   {/* Heading Section */}
   <Heading as="h2" size="xl" textAlign="center" mb={4}>
    Car-Expert Happy Partners
   </Heading>
   <Text fontSize="lg" color={textColor} textAlign="center" mb={8}>
    Hear it from the workshop owners themselves!
   </Text>

   {/* Carousel Component */}
   <Box mt={4}>
    <Slider {...settings}>
     {testimonials.map((testimonial) => (
      <Box key={testimonial.id} px={4}>
       {/* Testimonial Card */}
       <Box
        bg={bgColor}
        mt={5}
        p={6}
        borderRadius="lg"
        boxShadow="lg"
        position="relative"
        minHeight="350px"
        display="flex"
        flexDirection="column"
        justifyContent="space-between"
       >
        {/* Quote Icon */}
        <FaQuoteLeft
         style={{
          position: "absolute",
          top: "-25px",
          left: "-5px",
          fontSize: "40px",
          color: quoteIconColor,
         }}
        />

        {/* Testimonial Text */}
        <Text fontSize="md" mb={4}>
         {testimonial.testimonial}
        </Text>

        {/* Author Info */}
        <Box borderTop="1px solid" borderColor="gray.200" pt={4}>
         <Grid templateColumns="50px 1fr" alignItems="center">
          <GridItem>
           <Avatar size="md">{testimonial.avatar || "A"}</Avatar>
          </GridItem>
          <GridItem pl={2}>
           <Text fontWeight="bold">{testimonial.name}</Text>
           <Text fontSize="sm" color={textColor}>
            {testimonial.business}
           </Text>
          </GridItem>
         </Grid>
        </Box>
       </Box>
      </Box>
     ))}
    </Slider>

    {/* Divider for styling */}
    <Box border="5px solid blue" width="200px" margin="50px auto" />
   </Box>
  </Container>
 );
};

export default FormTestimonials;
