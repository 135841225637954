import React, { useEffect } from "react";
import { useSearchParams, useNavigate } from "react-router-dom";
import axios from "axios";
import { Box, Text } from "@chakra-ui/react";

const PaymentStatus = () => {
 const [searchParams] = useSearchParams();
 const transactionId = searchParams.get("id");
 const navigate = useNavigate();

 useEffect(() => {
  const checkPaymentStatus = async () => {
   if (!transactionId) return;

   try {
    const response = await axios.get(
     `https://app-api.carexpert.org.in/api/status?id=${transactionId}`
    );
    console.log("Payment Status:", response.data);
    // Handle the response as needed
   } catch (error) {
    console.error("Error checking payment status:", error);
   }
  };

  checkPaymentStatus();
 }, [transactionId]);

 return (
  <Box p={5}>
   <Text>Checking Payment Status...</Text>
  </Box>
 );
};

export default PaymentStatus;
