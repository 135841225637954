import React from "react";
import { Box, Grid, Text, Icon, Center } from "@chakra-ui/react";
import {
 FaLaptop,
 FaUsers,
 FaChalkboardTeacher,
 FaMotorcycle,
} from "react-icons/fa";

// Array to hold step data
const steps = [
 { id: "1", title: "Register with us", icon: FaLaptop },
 {
  id: "2",
  title: "On-boarding within 24 hours",
  description: "with minimal sign-up fee",
  icon: FaUsers,
 },
 {
  id: "3",
  title: "Training from Car-Expert managers",
  icon: FaChalkboardTeacher,
 },
 { id: "4", title: "Start delivering", icon: FaMotorcycle },
 { id: "5", title: "Start delivering", icon: FaMotorcycle },
 { id: "6", title: "Start delivering", icon: FaMotorcycle },
];

const SignUpSteps = () => {
 return (
  <Box bg="white" py={10} px={5}>
   {/* Main Heading */}
   <Text
    fontSize="4xl"
    fontWeight="bold"
    textAlign="center"
    mb={2}
    color="blackAlpha.900"
   >
    Join Car-Expert in 4 easy steps
   </Text>

   {/* Steps Grid */}
   <Grid
    templateColumns={["1fr", "repeat(2, 1fr)", "repeat(6, 1fr)"]}
    gap={6}
    alignItems={"center"}
    justifyContent={"center"}
    justifyItems="center"
    mt={8}
   >
    {steps.map((step) => (
     <Box
      w="200px"
      height={"250px"}
      key={step.id}
      bg="white"
      borderRadius="15px"
      border="2px solid #66C23D"
      boxShadow="md"
      display={"flex"}
      flexDirection={"column"}
      alignItems={"center"}
      justifyContent={"center"}
      textAlign="center"
      p={6}
     >
      <Center
       bg="#66C23D"
       w="60px"
       h="60px"
       borderRadius="full"
       color="white"
       fontSize="2xl"
       fontWeight="bold"
       mb={4}
      >
       {step.id}
      </Center>
      <Text fontWeight="semibold" fontSize="lg" mb={3}>
       {step.title}
      </Text>
      {step.description && (
       <Text fontSize="sm" color="gray.500" mb={3}>
        {step.description}
       </Text>
      )}
      <Icon as={step.icon} boxSize={10} color="#66C23D" />
     </Box>
    ))}
   </Grid>
  </Box>
 );
};

export default SignUpSteps;
