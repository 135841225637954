import {
  Accordion,
  AccordionButton,
  AccordionItem,
  Box,
  Container,
  Flex,
  Heading,
  Image,
  ListItem,
  SimpleGrid,
  UnorderedList,
  AccordionPanel,
  Text,
} from "@chakra-ui/react";

import { MinusIcon, AddIcon } from "@chakra-ui/icons";
import banner from "../../Assets/images/franchisee_banner.png";
import ApplicationForm from "./ApplicationForm";
const Franchisee = () => {
  const data = [
    {
      question: "Do we require a physical office space?",
      answer: "No",
    },
    {
      question: "Will the company provide software training support?",
      answer: "Yes",
    },
    {
      question:
        "Do we have to pay any additional cost for software upgrades and updates?",
      answer: "No",
    },
    {
      question: "Will the company provide washing training?",
      answer: "Yes",
    },
    {
      question: "Do we need to market ourself?",
      answer: "No, Car Expert provides marketing and brand awareness services.",
    },
    {
      question: "Do we need to hire a tele caller?",
      answer: "Not compulsory",
    },
    {
      question: "Can we purchase raw materials from outside?",
      answer:
        "No, partner managers are required to purchase the raw material from Car Expert as we only use rigorously tested consumables for assured quality results.",
    },
    {
      question: "Can I able to track rider activity and status?",
      answer: "Yes",
    },
    {
      question: "Does Car Expert charge royalty fees?",
      answer:
        "Yes,  We Have Charge 10% Roality Fees To Our Franchise Partner on Total Sales In a Month.",
    },
    {
      question: "Do franchise owners get a chance to earn additional income?",
      answer:
        "Yes, Car Expert pays back 25% of net margins gained from provided leads.",
    },
  ];

  return (
    <>
      <Box height={"100%"} width={"100%"}>
        <Image
          src={banner}
          alt="service banner"
          height={{ base: "40vh", md: "40vh" }}
          width={"100%"}
          objectFit={"cover"}
          filter={"brightness(0.8)"}
        />
      </Box>
      <Flex
        width={"100%"}
        bg="#000d6b"
        borderTop="1px solid"
        borderColor="gray.200"
        justifyContent={"space-between"}
        px={5}
        py={10}
        flexDirection={{base:"column",md:"column",lg:"row",xl:"row"}}
        gap={{base:6,md:6,lg:0}}
      >
        <Box width={{base:"100%",md:"100%",lg:"50%",xl:"50%"}} px={5}>
          <Text color={"white"} fontWeight={900} fontSize={{base:"1.1rem",md:"1.3rem",lg:"2rem",xl:"2rem"}}>
            Best authorised partner Business in Car Cleaning & Disinfectant
            Services.
          </Text>
          <Text color={"white"} fontWeight={400} fontSize={{base:"0.9rem",md:"1.4rem",lg:"1.4rem",xl:"1.4rem"}}>
            India's most prominent car wash authorised partner.
          </Text>
          <Text color={"white"} fontWeight={400} fontSize={{base:"0.9rem",md:"1.4rem",lg:"1.4rem",xl:"1.4rem"}}>
            India's Largest Car Washing Chain | 30+ Location!
          </Text>
        </Box>

        <ApplicationForm />
      </Flex>
      <Container
        maxW="container.xl"
        py={12}
        width={{ base: "100%", md: "70%" }}
        margin={"auto"}
      >
        <SimpleGrid columns={{ base: 1, md: 2, lg: 3 }} spacing={10}>
          <Box
            textAlign="center"
            p={5}
            borderWidth={1}
            borderRadius="md"
            boxShadow="md"
          >
            <Box
              fontSize={{ base: "1.8rem", md: "2rem" }}
              fontWeight="bold"
              color="#000D6B"
            >
              Upto 120%
            </Box>
            <Heading mt={3} fontSize="lg">
              Annual Returns
            </Heading>
          </Box>

          <Box
            textAlign="center"
            p={5}
            borderWidth={1}
            borderRadius="md"
            boxShadow="md"
          >
            <Box
              fontSize={{ base: "1.8rem", md: "2rem" }}
              fontWeight="bold"
              color="#000D6B"
            >
              Upto 65%
            </Box>
            <Heading mt={3} fontSize="lg">
              Gross Margins
            </Heading>
          </Box>

          <Box
            textAlign="center"
            p={5}
            borderWidth={1}
            borderRadius="md"
            boxShadow="md"
          >
            <Box
              fontSize={{ base: "1.8rem", md: "2rem" }}
              fontWeight="bold"
              color="#000D6B"
            >
              1,76,000 Lac
            </Box>
            <Heading mt={3} fontSize="lg">
              Investment
            </Heading>
          </Box>
        </SimpleGrid>
      </Container>

      <Flex
        width={{ base: "90%", md: "70%" }}
        margin={"auto"}
        flexDirection={"column"}
        py={4}
        gap={5}
      >
        <Heading
          color={"#000D6B"}
          fontSize={{ base: "1.3rem", md: "1.8rem" }}
          fontWeight={900}
        >
          What Is Car Expert Offering ?
        </Heading>
        <UnorderedList
          spacing={3}
          fontSize={{ base: "0.9rem", md: "1rem" }}
          fontWeight={500}
          color={"gray.700"}
        >
          <ListItem>
            Marketing and Orders on your CRM from the Next Day
          </ListItem>
          <ListItem>Washer Operations App</ListItem>
          <ListItem>
            Complete CRM to Book Appointments, Manage Leads, Auto Reminders,
            Manage and Live Track Employees, Inventory Management, Accounting,
            Control Pricing, Promotions Add Additional Service.
          </ListItem>
          <ListItem>Service and Support.</ListItem>
          <ListItem>Service Partner Training.</ListItem>
          <ListItem>Service Partner Hiring Support.</ListItem>
          <ListItem>User Application.</ListItem>
          <ListItem>Quality Supplies at Best Cost.</ListItem>
          <ListItem>Operation Process.</ListItem>
        </UnorderedList>
      </Flex>

      <Flex
        width={{ base: "90%", md: "70%" }}
        margin={"auto"}
        flexDirection={"column"}
        gap={2}
        py={4}
      >
        <Heading fontSize={{ base: "1.3rem", md: "1.7rem" }} color={"#000D6B"}>
          Frequently Asked Questions
        </Heading>
        <Accordion defaultIndex={[0]} allowToggle>
          {data.map((item, index) => (
            <AccordionItem key={index} width={{ base: "100%", md: "60%" }}>
              {({ isExpanded }) => (
                <>
                  <h2>
                    <AccordionButton
                      _expanded={{ bg: "blue.100" }} 
                      border="3px solid"
                      borderColor="#000D6B"
                      borderRadius="md"
                    >
                      <Box
                        as="span"
                        flex="1"
                        textAlign="left"
                        color="#000D6B"
                        fontSize={{ base: "0.9rem", md: "1rem" }}
                      >
                        {item.question}
                      </Box>
                      {isExpanded ? (
                        <MinusIcon fontSize="12px" color="#000D6B" />
                      ) : (
                        <AddIcon fontSize="12px" color="#000D6B" />
                      )}
                    </AccordionButton>
                  </h2>
                  <AccordionPanel
                    pb={4}
                    bg="gray.50"
                    borderTop="1px solid"
                    borderColor="gray.200"
                  >
                    {item.answer}
                  </AccordionPanel>
                </>
              )}
            </AccordionItem>
          ))}
        </Accordion>
      </Flex>
    </>
  );
};

export default Franchisee;
