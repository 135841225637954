import React, { useEffect, useState } from "react";
import Slider from "react-slick";
import {
 Box,
 Grid,
 useBreakpointValue,
 Text,
 Heading,
 Container,
} from "@chakra-ui/react";
import axios from "axios";
import WhyCarExpertPage from "../pages/WhyCarExpertPage";

const HeroSlider = () => {
 const [slides, setSlides] = useState([]);

 // Slider settings
 const settings = {
  fade: true,
  speed: 2000,
  autoplaySpeed: 3000,
  infinite: true,
  autoplay: true,
  slidesToShow: 1,
  slidesToScroll: 1,
  pauseOnHover: false,
 };

 // Fetch data from the API
 useEffect(() => {
  const getBanners = async () => {
   try {
    const response = await axios.get(
     "https://app-api.carexpert.org.in/api/offers"
    );
    setSlides(response.data.offers);
   } catch (error) {
    console.error("Error fetching slides:", error);
   }
  };
  getBanners();
 }, []);

 // Ensure slides are loaded before rendering
 if (!slides || slides.length === 0) {
  return <div>Loading...</div>; // Render loader or placeholder
 }

 return (
  <Box mt={5}>
   {/* Layout grid */}
   <Grid
    height={{ base: "50vh", md: "60vh" }} // Responsive height
    templateColumns={{ base: "1fr", md: "70% 28%" }} // Grid layout
    gap={4}
   >
    {/* Slider Section */}
    <Box
     display="block" // Ensure slider is always displayed
     flex="1"
     position="relative"
     overflow="hidden"
    >
     <Slider {...settings}>
      {slides.map((slide, index) => (
       <Box key={index} position="relative">
        <img
         src={slide.imageUrl}
         alt={slide.title}
         style={{
          width: "100%",
          height: "100%",
          objectFit: "fill",
          maxHeight: "60vh", // Ensure slider fits in view
         }}
        />
       </Box>
      ))}
     </Slider>
    </Box>

    {/* Sidebar Section */}
    <Box
     display={{ base: "none", md: "flex" }} // Hide sidebar on mobile
     flexDirection="column"
     justifyContent="space-between"
    >
     <WhyCarExpertPage />
    </Box>
   </Grid>
  </Box>
 );
};

export default HeroSlider;

// import React, { useEffect, useState } from "react";
// import Slider from "react-slick";
// import {
//  Box,
//  Button,
//  Container,
//  Grid,
//  Stack,
//  Heading,
//  Text,
// } from "@chakra-ui/react";
// import axios from "axios";
// import WhyCarExpertPage from "../pages/WhyCarExpertPage";

// const HeroSlider = () => {
//  const [slides, setSlides] = useState([]);

//  const settings = {
//   fade: true,
//   speed: 2000,
//   autoplaySpeed: 3000,
//   infinite: true,
//   autoplay: true,
//   slidesToShow: 1,
//   slidesToScroll: 1,
//   pauseOnHover: false,
//  };

//  // 1024*500 size

//  // Fetch data from the API
//  useEffect(() => {
//   const getBanners = async () => {
//    try {
//     const response = await axios.get(
//      "https://app-api.carexpert.org.in/api/offers"
//     );
//     setSlides(response.data.offers);
//    } catch (error) {
//     console.error("Error fetching slides:", error);
//    }
//   };
//   getBanners();
//  }, []);

//  // Ensure slides are loaded before rendering
//  if (!slides || slides.length === 0) {
//   return <div>Loading...</div>; // You can also render a loader or placeholder
//  }

//  return (
//   <Box>
//    {/* Desktop layout: 70% Hero Image and 30% Sidebar */}
//    <Grid
//     height="60vh"
//     templateColumns={{ base: "1fr", md: "70% 28%" }} // Mobile: 100% width, Desktop: 70% for image, 30% for sidebar
//     gap={4}
//    >
//     <Box position="relative">
//      <Slider {...settings}>
//       {slides.map((slide, index) => (
//        <Box key={index}>
//         <img
//          src={"/test.webp"}
//          //  srcSet={`${"/test.webp"}?w=800 800w, ${"/test.webp"}?w=1280 1280w, ${"/test.webp"}?w=1920 1920w`}
//          srcSet={`${slide.imageUrl}?w=800 800w, ${slide.imageUrl}?w=1280 1280w, ${slide.imageUrl}?w=1920 1920w`}
//          sizes="(max-width: 600px) 800px, (max-width: 1200px) 1280px, 1920px"
//          alt={slide.title}
//          style={{ width: "100%", height: "60vh", objectFit: "fill" }}
//         />
//        </Box>
//       ))}
//      </Slider>
//     </Box>

//     {/* 30% - Sidebar (Features, Ratings, Car Brands, Models) */}
//     <Box display="flex" flexDirection="column" justifyContent="space-between">
//      <WhyCarExpertPage />
//     </Box>
//    </Grid>
//   </Box>
//  );
// };

// export default HeroSlider;
