import React from "react";
import {
 Modal,
 ModalOverlay,
 ModalContent,
 ModalHeader,
 ModalCloseButton,
 ModalBody,
} from "@chakra-ui/react";

const DialogTemplate = ({ isOpen, onClose, title, children, size = "md" }) => (
 <Modal isOpen={isOpen} onClose={onClose} size={size}>
  <ModalOverlay />
  <ModalContent>
   <ModalHeader>{title}</ModalHeader>
   <ModalCloseButton />
   <ModalBody>{children}</ModalBody>
  </ModalContent>
 </Modal>
);

export default DialogTemplate;

// import React from "react";
// import {
//  Modal,
//  ModalOverlay,
//  ModalContent,
//  ModalHeader,
//  ModalCloseButton,
//  ModalBody,
//  useDisclosure,
// } from "@chakra-ui/react";

// const DialogTemplate = ({
//  isOpen,
//  onClose,
//  title,
//  children,
//  onSubmit,
//  size = "md",
// }) => (
//  <Modal isOpen={isOpen} onClose={onClose} size={size}>
//   <ModalOverlay />
//   <ModalContent>
//    <ModalHeader>{title}</ModalHeader>
//    <ModalCloseButton />
//    <ModalBody>{React.cloneElement(children, { onSubmit })}</ModalBody>
//   </ModalContent>
//  </Modal>
// );

// export default DialogTemplate;
