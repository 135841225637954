import React from "react";
import {
  Box,
  Text,
  Image,
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  Flex,
  SimpleGrid,
  Button,
  VStack,
  Badge,
  Grid,
  GridItem,
} from "@chakra-ui/react";
import { CiSettings } from "react-icons/ci";
import { FaRegUser } from "react-icons/fa6";
import { LuCar } from "react-icons/lu";
import { TfiCup } from "react-icons/tfi";

import img1 from "../../Assets/images/img1.jpeg";
import img2 from "../../Assets/images/img2.jpeg";
import img3 from "../../Assets/images/img3.jpeg";
import img4 from "../../Assets/images/img4.jpeg";
import img6 from "../../Assets/images/img6.jpeg";
import img7 from "../../Assets/images/img7.jpeg";
import banner from "../../Assets/images/about_banner.jpg";
import { MdPeople } from "react-icons/md";
import { FaCar } from "react-icons/fa6";
import { LiaPeopleCarrySolid } from "react-icons/lia";
import { GiChampions } from "react-icons/gi";
import { FaFontAwesomeFlag } from "react-icons/fa";
// Define ServiceCard Component
const ServiceCard = ({ title, description, price, image }) => {
  return (
    <VStack
      border="1px solid #e2e8f0"
      borderRadius="md"
      overflow="hidden"
      spacing={4}
      p={4}
      align="start"
      boxShadow="md"
      bg="#1a1a1a"
    >
      <Box
        position="relative"
        w="full"
        bg="#1a1a1a"
        alignContent={"center"}
        alignItems={"center"}
        justifyContent={"center"}
      >
        <Image src={image} alt={title} objectFit="cover" borderRadius="md" />
        <Badge
          position="absolute"
          top={2}
          right={2}
          bg="red.500"
          color="black"
          fontSize="sm"
        >
          Starting from ₹ {price}
        </Badge>
      </Box>
      <Text fontWeight="bold" fontSize="lg" color="gray.700">
        {title}
      </Text>
      <Text fontSize="sm" color="gray.500">
        {description}
      </Text>
    </VStack>
  );
};

// Define ServicesGrid Component
const ServicesGrid = () => {
  const services = [
    {
      title: "Interior Car Cleaning & Washing",
      description:
        "Get the best interior car cleaning services to the convenience of your doorstep. Our team of car cleaning experts will transform your vehicle into showroom-like condition.",
      price: 399,
      image: img1,
    },
    {
      title: "Exterior Car Cleaning & Washing",
      description:
        "Who likes seeing their car look dull and dirty? No one, right? Also, who wouldn’t like the convenience of washing and cleaning their car at their fingertips?",
      price: 349,
      image: img4,
    },
    {
      title: "Car Dry Cleaning",
      description:
        "With our complete car dry cleaning services, we're all about making your life easier. As the leading 360 car care service provider, we bring our expertise and advanced machines straight to your doorstep.",
      price: 799,
      image: img7,
    },
    {
      title: "Complete Car Body Polish and Wax",
      description:
        "Who doesn't want to make their car look like it just rolled off the showroom floor? To give your car that showroom shine, book our complete car body polish and wax services.",
      price: 899,
      image: img2,
    },
    {
      title: "Car Odour Removal",
      description:
        "It's deadly to deal with some unpleasant smells in a car. Especially the smell of smoke and any kind of food. We have got expertise in car odour removal.",
      price: 799,
      image: img6,
    },
  ];

  return (
    <Grid
      templateColumns={{
        base: "1fr",
        md: "repeat(2, 1fr)",
        lg: "repeat(3, 1fr)",
      }}
      gap={10}
      p={3}
    >
      {services.map((service, index) => (
        <GridItem
          key={index}
          borderRadius="lg"
          overflow="hidden"
          bg="white"
          p={4}
          d="flex"
          flexDirection="column"
          justifyContent="space-between"
          height="100%"
          boxShadow="lg"
          transition="transform 0.3s ease, box-shadow 0.3s ease"
          _hover={{ transform: "scale(1.05)", boxShadow: "xl" }}
        >
          <Image
            src={service.image}
            alt={service.title}
            objectFit="cover"
            width="100%"
            height="400px"
            borderRadius="md"
          />
          <Box p={4} h={"30vh"}>
            <Text fontWeight="bold" fontSize="1.1rem" color="#000D6B" mb={2}>
              {service.title}
            </Text>
            <Text fontSize="0.8rem" color="black" mb={4}>
              {service.description}
            </Text>
          </Box>
          <Button
            mt="auto"
            width="full"
            background={"#000D6B"}
            color={"white"}
            borderRadius="md"
          >
            Starting from ₹{service.price}
          </Button>
        </GridItem>
      ))}
    </Grid>
  );
};

// About Component
function About() {
  return (
    <Box>
      {/* Header Section */}
      <Box
        bgImage={banner}
        bgPos="top"
        bgSize="cover"
        h="50vh"
        display="flex"
        alignItems="end"
        justifyContent="center"
        position="relative"
      ></Box>

      {/* About Company Section */}
      <Box py={4} width={{ base: "90%", lg: "80%" }} margin="auto">
        <Flex
          direction={["column", "column", "row"]}
          color="black"
          width="100%"
          margin="auto"
          justifyContent={"space-between"}
          alignItems="center"
        >
          {/* Text Section */}
          <Box width={{ base: "100%", lg: "60%" }}>
            <Text
              fontSize={{ base: "1.4rem", lg: "2xl" }}
              fontWeight="bold"
              mb={0}
              color="black"
            >
              ABOUT COMPANY
            </Text>
            <Box w="90px" h="5px" bg="red.600" mb={4} borderRadius="full"></Box>
            <Text fontSize={"0.9rem"} color="black">
              Car Expert is a complete car care service platform that brings
              super convenience to car owners. We understand how hectic it is to
              take your vehicle to the service centers, which is why we deliver
              our all-in-one car care solutions straight to your doorstep.
            </Text>
            <Text fontSize={"0.9rem"} color="black">
              With just a click of a button using our user-friendly app or our
              website, you can schedule car services from the comfort of your
              home, office, or anywhere in the city. Our highly experienced
              automobile technicians will come to your place with advanced
              equipment and handle the entire process, saving you the time and
              effort of visiting a car cleaning facility.
            </Text>

            <Grid
              templateColumns={{ base: "repeat(1,1fr)", lg: "repeat(2,1fr)" }}
              width={"100%"}
              gap={12}
            >
              <Flex alignItems={"center"} justifyContent={"center"} gap={4}>
                <Box border={"1px solid #000D6B"} borderRadius={"full"} p={1}>
                  <CiSettings size={22} color="#000D6B" />
                </Box>
                <Flex
                  flexDirection={"column"}
                  justifyContent={"center"}
                  // alignItems={"center"}
                >
                  <Text fontSize="1rem" fontWeight="bold" color="#000D6B">
                    Say Goodbye To The Hassle
                  </Text>
                  <Text fontSize="0.9rem" color="black">
                    Car Expert saves your day! Get car care services at your
                    doorstep, hassle-free.
                  </Text>
                </Flex>
              </Flex>
              <Flex alignItems={"center"} justifyContent={"center"} gap={4}>
                <Box border={"1px solid #000D6B"} borderRadius={"full"} p={1}>
                  <FaRegUser size={22} color="#000D6B" />
                </Box>
                <Flex
                  flexDirection={"column"}
                  justifyContent={"center"}
                  // alignItems={"center"}
                >
                  <Text fontSize="1rem" fontWeight="bold" color="#000D6B">
                    360° Car Care Solutions
                  </Text>
                  <Text fontSize="0.9rem" color="black">
                    Convenient services: wash, repair, painting, and more,
                    scheduled citywide.
                  </Text>
                </Flex>
              </Flex>
              <Flex alignItems={"center"} justifyContent={"center"} gap={4}>
                <Box border={"1px solid #000D6B"} borderRadius={"full"} p={1}>
                  <LuCar size={22} color="#000D6B" />
                </Box>
                <Flex
                  flexDirection={"column"}
                  justifyContent={"center"}
                  // alignItems={"center"}
                >
                  <Text fontSize="1rem" fontWeight="bold" color="#000D6B">
                    Go Green with Car Expert
                  </Text>
                  <Text fontSize="0.9rem" color="black">
                    Eco-friendly machines and pH-neutral shampoos for gentle,
                    green car care.
                  </Text>
                </Flex>
              </Flex>
              <Flex alignItems={"center"} justifyContent={"center"} gap={4}>
                <Box border={"1px solid #000D6B"} borderRadius={"full"} p={1}>
                  <TfiCup size={22} color="#000D6B" />
                </Box>
                <Flex
                  flexDirection={"column"}
                  justifyContent={"center"}
                  // alignItems={"center"}
                >
                  <Text fontSize="1rem" fontWeight="bold" color="#000D6B">
                    DoorStep & Pick and Drop
                  </Text>
                  <Text fontSize="0.9rem" color="black">
                    Free pickup and drop & Doorstep Services for your
                    convenience, in all segments.
                  </Text>
                </Flex>
              </Flex>
            </Grid>
          </Box>

          {/* Image Section */}
          <Box width={{ base: "100%", lg: "30%" }}>
            <Image
              src={img2}
              alt="About Image"
              // w="100%"
              // height={400}
              borderRadius="md"
            />
          </Box>
        </Flex>
      </Box>

      <Flex
        flexDirection={"column"}
        gap={3}
        py={16}
        width={{ base: "100%", lg: "80%" }}
        margin={"auto"}
      >
        <Text
          fontSize={{ base: "2rem", lg: "3rem" }}
          fontWeight="bold"
          textAlign="center"
          color={"#000D6B"}
        >
          OUR BEST{" "}
          <Text as="span" color={"red"}>
            {" "}
            SERVICES
          </Text>
        </Text>
        <Text
          fontSize={{ base: "1rem", lg: "1.1rem" }}
          textAlign="center"
          color={"black"}
          width={{ base: "90%", lg: "50%" }}
          margin={"auto"}
        >
          With Car Expert, you can choose from a wide assortment of car services
          in Delhi.Apart from periodic car servicing, we also offer:
        </Text>
        <ServicesGrid />
      </Flex>

      <Box py={10} bg={"gray.100"}>
        <Grid
          templateColumns={{
            base: "repeat(1,1fr)",
            md: "repeat(1,1fr)",
            lg: "repeat(4,1fr)",
            xl: "repeat(4,1fr)",
          }}
          width={"60%"}
          margin={"auto"}
          gap={10}
        >
          <Flex
            alignItems={"center"}
            justifyContent={"center"}
            borderRadius={"lg"}
            gap={2}
            flexDirection={"column"}
          >
            <Box
              border={"1px solid #000D6B"}
              borderRadius={"full"}
              p={8}
              background={"#000D6B"}
            >
              <MdPeople size={40} color="white" />
            </Box>
            <Flex
              flexDirection={"column"}
              justifyContent={"center"}
              alignItems={"center"}
            >
              <Text
                fontSize={{
                  base: "1.2rem",
                  md: "1.2rem",
                  lg: "1.4rem",
                  xl: "1.6rem",
                }}
                fontWeight={900}
                color="#000D6B"
              >
                100 +
              </Text>
              <Text>Ninjas</Text>
            </Flex>
          </Flex>

          <Flex
            alignItems={"center"}
            justifyContent={"center"}
            borderRadius={"lg"}
            gap={2}
            flexDirection={"column"}
          >
            <Box
              border={"1px solid #000D6B"}
              borderRadius={"full"}
              p={8}
              background={"#000D6B"}
            >
              <MdPeople size={40} color="white" />
            </Box>
            <Flex
              flexDirection={"column"}
              justifyContent={"center"}
              alignItems={"center"}
            >
              <Text
                fontSize={{
                  base: "1.2rem",
                  md: "1.2rem",
                  lg: "1.4rem",
                  xl: "1.6rem",
                }}
                fontWeight={900}
                color="#000D6B"
              >
                30000 +
              </Text>
              <Text>Customers</Text>
            </Flex>
          </Flex>

          <Flex
            alignItems={"center"}
            justifyContent={"center"}
            borderRadius={"lg"}
            gap={2}
            flexDirection={"column"}
          >
            <Box
              border={"1px solid #000D6B"}
              borderRadius={"full"}
              p={8}
              background={"#000D6B"}
            >
              <MdPeople size={40} color="white" />
            </Box>
            <Flex
              flexDirection={"column"}
              justifyContent={"center"}
              alignItems={"center"}
            >
              <Text
                fontSize={{
                  base: "1.2rem",
                  md: "1.2rem",
                  lg: "1.4rem",
                  xl: "1.6rem",
                }}
                fontWeight={900}
                color="#000D6B"
              >
                150 +
              </Text>
              <Text>Workshops</Text>
            </Flex>
          </Flex>

          <Flex
            alignItems={"center"}
            justifyContent={"center"}
            borderRadius={"lg"}
            gap={2}
            flexDirection={"column"}
          >
            <Box
              border={"1px solid #000D6B"}
              borderRadius={"full"}
              p={8}
              background={"#000D6B"}
            >
              <MdPeople size={40} color="white" />
            </Box>
            <Flex
              flexDirection={"column"}
              justifyContent={"center"}
              alignItems={"center"}
            >
              <Text
                fontSize={{
                  base: "1.2rem",
                  md: "1.2rem",
                  lg: "1.4rem",
                  xl: "1.6rem",
                }}
                fontWeight={900}
                color="#000D6B"
              >
                4.7 +
              </Text>
              <Text>Rating</Text>
            </Flex>
          </Flex>
        </Grid>
      </Box>

      <Box py={10}>
        <Box
          width={{ base: "70%", md: "70%", lg: "50%", xl: "50%" }}
          margin={"auto"}
          textAlign={"center"}
        >
          <Text
            fontSize={{
              base: "1rem",
              md: "1.1rem",
              lg: "1.2rem",
              xl: "1.9rem",
            }}
            fontWeight={900}
          >
            Why Choose <span style={{ color: "#000D6B" }}> Car Expert</span>
          </Text>
          <Text
            fontSize={{
              base: "1rem",
              md: "1.1rem",
              lg: "1.2rem",
              xl: "1.3rem",
            }}
            fontWeight={600}
            color={"#000D6B"}
          >
            Choosing CarExpert for your car care? Great choice! Here's why
            you'll love us:
          </Text>
        </Box>

        <Grid
          templateColumns={{
            base: "repeat(1,1fr)",
            md: "repeat(1,1fr)",
            lg: "repeat(2,1fr)",
            xl: "repeat(2,1fr)",
          }}
          width={{ base: "90%", md: "90%", lg: "70%", xl: "70%" }}
          margin={"auto"}
          gap={10}
        >
          <Flex
            alignItems={"center"}
            justifyContent={"center"}
            borderRadius={"lg"}
            py={5}
            gap={4}
            border={"2px solid #000D6B"}
            px={6}
            flexDirection={{
              base: "column",
              md: "column",
              lg: "row",
              xl: "row",
            }}
          >
            <Box
              border={"1px solid #000D6B"}
              borderRadius={"full"}
              p={5}
              background={"#000D6B"}
            >
              <MdPeople size={40} color="white" />
            </Box>
            <Flex flexDirection={"column"} justifyContent={"center"}>
              <Text
                fontSize={{
                  base: "1.2rem",
                  md: "1.2rem",
                  lg: "1.4rem",
                  xl: "1.7rem",
                }}
                fontWeight="bold"
                color="#000D6B"
              >
                Super Convenient
              </Text>
              <Text fontSize="1rem" color="black">
                Imagine never having to drive to the service center again. With
                CarExpert, just tap on your phone, and we come to you. It's car
                care on your terms and your timetable.
              </Text>
            </Flex>
          </Flex>

          <Flex
            alignItems={"center"}
            justifyContent={"center"}
            borderRadius={"lg"}
            py={5}
            gap={4}
            border={"2px solid #000D6B"}
            px={6}
            flexDirection={{
              base: "column",
              md: "column",
              lg: "row",
              xl: "row",
            }}
          >
            <Box
              border={"1px solid #000D6B"}
              borderRadius={"full"}
              p={5}
              background={"#000D6B"}
            >
              <FaCar size={40} color="white" />
            </Box>
            <Flex flexDirection={"column"} justifyContent={"center"}>
              <Text
                fontSize={{
                  base: "1.2rem",
                  md: "1.2rem",
                  lg: "1.4rem",
                  xl: "1.7rem",
                }}
                fontWeight="bold"
                color="#000D6B"
              >
                Doorstep Services
              </Text>
              <Text fontSize="1rem" color="black">
                Whether your car needs a quick wash or a repair job, CarExpert's
                got your back. We solve all your car troubles from wherever you
                are. So versatile!
              </Text>
            </Flex>
          </Flex>

          <Flex
            alignItems={"center"}
            justifyContent={"center"}
            borderRadius={"lg"}
            py={5}
            gap={4}
            border={"2px solid #000D6B"}
            px={6}
            flexDirection={{
              base: "column",
              md: "column",
              lg: "row",
              xl: "row",
            }}
          >
            <Box
              border={"1px solid #000D6B"}
              borderRadius={"full"}
              p={5}
              background={"#000D6B"}
            >
              <LiaPeopleCarrySolid size={40} color="white" />
            </Box>
            <Flex flexDirection={"column"} justifyContent={"center"}>
              <Text
                fontSize={{
                  base: "1.2rem",
                  md: "1.2rem",
                  lg: "1.4rem",
                  xl: "1.7rem",
                }}
                fontWeight="bold"
                color="#000D6B"
              >
                Eco-friendly All The Way
              </Text>
              <Text fontSize="1rem" color="black">
                Here's to helping the planet while taking care of your ride!
                CarExpert uses lithium-ion powered machinery and eco-friendly
                shampoos that are as gentle on the earth as they are on your
                car's sparkling finish.
              </Text>
            </Flex>
          </Flex>

          <Flex
            alignItems={"center"}
            justifyContent={"center"}
            borderRadius={"lg"}
            py={5}
            gap={4}
            border={"2px solid #000D6B"}
            px={6}
            flexDirection={{
              base: "column",
              md: "column",
              lg: "row",
              xl: "row",
            }}
          >
            <Box
              border={"1px solid #000D6B"}
              borderRadius={"full"}
              p={5}
              background={"#000D6B"}
            >
              <CiSettings size={40} color="white" />
            </Box>
            <Flex flexDirection={"column"} justifyContent={"center"}>
              <Text
                fontSize={{
                  base: "1.2rem",
                  md: "1.2rem",
                  lg: "1.4rem",
                  xl: "1.7rem",
                }}
                fontWeight="bold"
                color="#000D6B"
              >
                Free Pick-up and Drop-off
              </Text>
              <Text fontSize="1rem" color="black">
                Got some major repairs? CarExpert makes it easy with
                complimentary pick-up and drop-off services. You don't have to
                worry about a thing.
              </Text>
            </Flex>
          </Flex>

          <Flex
            alignItems={"center"}
            justifyContent={"center"}
            borderRadius={"lg"}
            py={5}
            gap={4}
            border={"2px solid #000D6B"}
            px={6}
            flexDirection={{
              base: "column",
              md: "column",
              lg: "row",
              xl: "row",
            }}
          >
            <Box
              border={"1px solid #000D6B"}
              borderRadius={"full"}
              p={5}
              background={"#000D6B"}
            >
              <GiChampions size={40} color="white" />
            </Box>
            <Flex flexDirection={"column"} justifyContent={"center"}>
              <Text
                fontSize={{
                  base: "1.2rem",
                  md: "1.2rem",
                  lg: "1.4rem",
                  xl: "1.7rem",
                }}
                fontWeight="bold"
                color="#000D6B"
              >
                All-in-One Solutions
              </Text>
              <Text fontSize="1rem" color="black">
                From an oil change to a full detail, we do it all. And the best
                part? All services come to your doorstep. It's comprehensive car
                care that doesn't cut corners.
              </Text>
            </Flex>
          </Flex>

          <Flex
            alignItems={"center"}
            justifyContent={"center"}
            borderRadius={"lg"}
            py={5}
            gap={4}
            border={"2px solid #000D6B"}
            px={6}
            flexDirection={{
              base: "column",
              md: "column",
              lg: "row",
              xl: "row",
            }}
          >
            <Box
              border={"1px solid #000D6B"}
              borderRadius={"full"}
              p={5}
              background={"#000D6B"}
            >
              <FaFontAwesomeFlag size={40} color="white" />
            </Box>
            <Flex flexDirection={"column"} justifyContent={"center"}>
              <Text
                fontSize={{
                  base: "1.2rem",
                  md: "1.2rem",
                  lg: "1.4rem",
                  xl: "1.7rem",
                }}
                fontWeight="bold"
                color="#000D6B"
              >
                Quality You Can Trust
              </Text>
              <Text fontSize="1rem" color="black">
                CarExpert isn't just about convenience. We have a team of
                seasoned technicians who know their stuff, ensuring your car
                gets the best service every single time.
              </Text>
            </Flex>
          </Flex>
        </Grid>
      </Box>
    </Box>
  );
}

export default About;
