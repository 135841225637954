import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

// Async thunk to fetch brands
export const fetchBrands = createAsyncThunk("brands/fetchBrands", async () => {
 const response = await axios.get(
  "https://app-api.carexpert.org.in/api/brands"
 );
 return response.data.data; // Returns the `data` array from the response
});

// Create a slice for managing brands
const brandsSlice = createSlice({
 name: "brands",
 initialState: {
  brands: [],
  loading: false,
  error: null,
 },
 reducers: {},
 extraReducers: (builder) => {
  builder
   .addCase(fetchBrands.pending, (state) => {
    state.loading = true;
   })
   .addCase(fetchBrands.fulfilled, (state, action) => {
    state.loading = false;
    state.brands = action.payload; // The `brands` array is updated with the fetched data
   })
   .addCase(fetchBrands.rejected, (state, action) => {
    state.loading = false;
    state.error = action.error.message;
   });
 },
});

export default brandsSlice.reducer;
