import React from 'react'

const CarDetails = () => {
  return (
    <div>
      CarDetails
    </div>
  )
}

export default CarDetails
