import React from "react";
import { Box, Heading, Text, Container, Image } from "@chakra-ui/react";
import Slider from "react-slick";

// Import slick carousel CSS
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

// Sample partner workshop data
const workshops = [
 {
  id: 1,
  name: "Motoring 65, Gurgaon",
  image: "/car-repair-svgrepo-com.svg", // Replace with actual image path
 },
 {
  id: 2,
  name: "Car-Expert - Nerul, Mumbai",
  image: "/auto-paint-service-svgrepo-com.svg", // Replace with actual image path
 },
 {
  id: 3,
  name: "Car-Expert - Diya Motors, Chennai",
  image: "/OilChangeIcon.svg", // Replace with actual image path
 },
 {
  id: 4,
  name: "3M Automobiles, Gurgaon",
  image: "/TireIcon.svg", // Replace with actual image path
 },
 {
  id: 5,
  name: "AutoFix Workshop, Pune",
  image: "/ToolsIcon.svg", // Replace with actual image path
 },
];

const WorkshopSlider = () => {
 // Slick carousel settings
 const settings = {
  dots: false,
  infinite: true,
  speed: 500,
  slidesToShow: 3,
  slidesToScroll: 1,
  autoplay: true,
  autoplaySpeed: 3000,
  arrows: true,
  responsive: [
   {
    breakpoint: 1024,
    settings: {
     slidesToShow: 2,
     slidesToScroll: 1,
    },
   },
   {
    breakpoint: 600,
    settings: {
     slidesToShow: 1,
     slidesToScroll: 1,
    },
   },
  ],
 };

 return (
  <Container maxW="container.xl" py={10}>
   {/* Title Section */}
   <Heading as="h2" size="xl" textAlign="center" mb={4}>
    Our Partner Workshops
   </Heading>
   <Text fontSize="lg" color="gray.500" textAlign="center" mb={8}>
    Here’s a sneak peek into the Car-Expert Partner Workshops
   </Text>

   {/* Carousel Component */}
   <Box mt={4}>
    <Slider {...settings}>
     {workshops.map((workshop) => (
      <Box key={workshop.id} px={4}>
       {/* Workshop Image and Caption */}
       <Box
        borderRadius="lg"
        overflow="hidden"
        boxShadow="lg"
        textAlign="center"
       >
        <Image
         src={workshop.image}
         alt={workshop.name}
         width="100%"
         height="200px"
         objectFit="cover"
        />
        <Text fontSize="lg" fontWeight="bold" mt={2}>
         {workshop.name}
        </Text>
       </Box>
      </Box>
     ))}
    </Slider>
   </Box>
  </Container>
 );
};

export default WorkshopSlider;
