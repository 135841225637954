import React, { useState } from "react";
import {
 Flex,
 Box,
 Text,
 Input,
 Textarea,
 Button,
 useToast,
 FormControl,
} from "@chakra-ui/react";
import axios from "axios";

const ApplicationForm = () => {
 const [formData, setFormData] = useState({
  name: "",
  email: "",
  whatsappNumber: "",
  state: "",
  city: "",
  budget: "",
  interest: "",
 });
 const toast = useToast();

 const handleChange = (e) => {
  setFormData({
   ...formData,
   [e.target.name]: e.target.value,
  });
 };

 const handleSubmit = async (e) => {
  e.preventDefault();
  try {
   const res = await axios.post(
    "https://app-api.carexpert.org.in/api/franchise",
    formData
   );
   console.log("Form data submitted:", formData);
   toast({
    title: "description Sent.",
    description:
     "We've received your description and will get back to you shortly.",
    status: "success",
    duration: 5000,
    isClosable: true,
   });
   setFormData({
    name: "",
    email: "",
    whatsappNumber: "",
    state: "",
    city: "",
    budget: "",
    interest: "",
   });
  } catch (error) {
   console.log("Error:", error);
   toast({
    title: "Submission Failed.",
    description:
     "There was an error submitting your description. Please try again later.",
    status: "error",
    duration: 5000,
    isClosable: true,
   });
  }
 };

 return (
  <Flex
   as="form"
   flexDirection={"column"}
   gap={6}
   width={{ base: "90%", md: "50%" }}
   margin={"auto"}
   onSubmit={handleSubmit}
  >
   <Box>
    <Text color={"white"} fontSize={{ base: "1.4rem", md: "2rem" }}>
     Franchise Application Form
    </Text>
    <Text color={"white"} fontSize={{ base: "0.8rem", md: "1rem" }}>
     To know more about the Car Expert Franchise, fill the details below and
     we'll get in touch asap
    </Text>
   </Box>

   <Flex
    flexDirection={{ base: "column", md: "column", lg: "row", xl: "row" }}
    gap={10}
   >
    <Flex
     gap={4}
     flexDirection={"column"}
     width={{ base: "100%", md: "100%", lg: "50%", xl: "50%" }}
    >
     <FormControl isRequired>
      <Input
       name="name"
       value={formData.name}
       onChange={handleChange}
       bg={"white"}
       color={"#718096"}
       fontSize={{ base: "0.7rem", md: "1rem" }}
       placeholder="Name"
      />
     </FormControl>
     <FormControl isRequired>
      <Input
       name="email"
       value={formData.email}
       onChange={handleChange}
       bg={"white"}
       color={"#718096"}
       fontSize={{ base: "0.7rem", md: "1rem" }}
       placeholder="Email"
      />
     </FormControl>
     <FormControl isRequired>
      <Input
       name="whatsappNumber"
       value={formData.whatsappNumber}
       onChange={handleChange}
       bg={"white"}
       color={"#718096"}
       fontSize={{ base: "0.7rem", md: "1rem" }}
       placeholder="WhatsApp Number"
      />
     </FormControl>

     <Text color={"white"} fontSize={{ base: "0.7rem", md: "0.9rem" }} mt={2}>
      All the details about the franchise would be shared over WhatsApp number
     </Text>

     <FormControl isRequired>
      <Input
       name="state"
       value={formData.state}
       onChange={handleChange}
       bg={"white"}
       color={"#718096"}
       fontSize={{ base: "0.7rem", md: "1rem" }}
       placeholder="State"
      />
     </FormControl>
     <FormControl isRequired>
      <Input
       name="city"
       value={formData.city}
       onChange={handleChange}
       bg={"white"}
       color={"#718096"}
       fontSize={{ base: "0.7rem", md: "1rem" }}
       placeholder="City"
      />
     </FormControl>
    </Flex>

    <Flex
     gap={4}
     flexDirection={"column"}
     width={{ base: "100%", md: "100%", lg: "50%", xl: "50%" }}
     justifyContent={"space-between"}
    >
     <Flex flexDirection={"column"} gap={6}>
      <FormControl isRequired>
       <Textarea
        name="budget"
        value={formData.budget}
        onChange={handleChange}
        bg={"white"}
        color={"#718096"}
        fontSize={{ base: "0.7rem", md: "1rem" }}
        placeholder="Do you have a budget of 2.5 lakhs set aside for a Car Expert Franchise?"
       />
      </FormControl>
      <FormControl isRequired>
       <Textarea
        name="interest"
        value={formData.interest}
        onChange={handleChange}
        bg={"white"}
        color={"#718096"}
        fontSize={{ base: "0.7rem", md: "1rem" }}
        placeholder="Details of interest for Car Expert Franchise? (Explain Why in 100 words)"
       />
      </FormControl>
     </Flex>

     <Flex justifyContent={"right"}>
      <Button
       type="submit"
       bg={"#FFFFFF"}
       color={"#000D6B"}
       borderRadius={"lg"}
       py={3}
       fontSize={"1.2rem"}
       border={"2px solid #FFFFFF"}
       width={{ base: "80%", md: "40%" }}
       margin={"auto"}
      >
       Register Now
      </Button>
     </Flex>
    </Flex>
   </Flex>
  </Flex>
 );
};

export default ApplicationForm;
