import React from "react";
import { Box, Flex, Image, Heading, Text, IconButton } from "@chakra-ui/react";
import { EditIcon } from "@chakra-ui/icons";

const CarCard = ({ selectedCar }) => {
 return (
  <Box
   maxW="400px"
   borderRadius="lg"
   overflow="hidden"
   bgGradient="linear(to-r, blue.500, purple.600)"
   p={4}
   display="flex"
   alignItems="center"
   justifyContent="space-between"
  >
   <Flex alignItems="center">
    <Image
     src={selectedCar.images}
     alt="Car Image"
     boxSize="80px"
     objectFit="cover"
     borderRadius="lg"
     mr={4}
    />

    {/* Car Details */}
    <Box>
     <Heading as="h4" size="md" color="white">
      Tata
     </Heading>
     <Text color="whiteAlpha.800">
      {selectedCar.title} {selectedCar.fuelType}
     </Text>
    </Box>
   </Flex>

   {/* Edit Icon */}
   <IconButton
    icon={<EditIcon />}
    aria-label="Edit Car"
    size="md"
    colorScheme="whiteAlpha"
    variant="ghost"
    color="white"
   />
  </Box>
 );
};

export default CarCard;
