import React, { useEffect, useState } from "react";
import {
 Box,
 Container,
 SimpleGrid,
 Text,
 Heading,
 Icon,
 Image,
 IconButton,
 Flex,
} from "@chakra-ui/react";
import {
 FaStar,
 FaStarHalfAlt,
 FaUsers,
 FaHome,
 FaCheckCircle,
} from "react-icons/fa";
import { EditIcon } from "@chakra-ui/icons"; // Assuming EditIcon is imported here
import { useDispatch, useSelector } from "react-redux";
import { fetchBrands } from "../../redux/brandsSlice";
import { Col, Row } from "reactstrap";
import FindCarForm from "../UI/FindCarForm";
import CarCard from "../CarSelection/CarCard";
// import FindCarForm from "./FindCarForm"; // Assuming this is the form for selecting a car
// import { Row, Col } from "react-bootstrap"; // Assuming react-bootstrap is being used

// Styled component for feature cards (converted to Chakra UI style)
const FeatureCard = ({ icon, title }) => {
 return (
  <Box
   bg="gray.100"
   borderRadius="lg"
   p={4}
   display="flex"
   flexDirection="column"
   alignItems="center"
   justifyContent="space-between"
   textAlign="center"
   h="110px"
   boxShadow="lg"
  >
   <Icon as={icon} boxSize={6} color="#000D6B" mb={2} />
   <Text fontSize="sm">{title}</Text>
  </Box>
 );
};

// Part 1: Feature Cards Section (Why Car-Expert?)
const FeatureCardsSection = () => {
 const features = [
  { icon: FaHome, title: "At-Home Convenience" },
  { icon: FaCheckCircle, title: "10,000+ hours of experience" },
  { icon: FaStar, title: "Trusted & Verified" },
  { icon: FaUsers, title: "20,000+ Satisfied Users" },
 ];

 return (
  <Container py={4} textAlign="center">
   <Heading as="h6" fontSize="lg" mb={6} textAlign={"left"}>
    Why Car-Expert?
   </Heading>
   <SimpleGrid columns={{ base: 2, md: 4 }} spacing={4}>
    {features.map((feature, index) => (
     <FeatureCard key={index} icon={feature.icon} title={feature.title} />
    ))}
   </SimpleGrid>
  </Container>
 );
};

// Part 2: Ratings and Registered Users Section
const RatingsAndUsersSection = () => {
 return (
  <Container py={8} textAlign="center">
   <SimpleGrid columns={{ base: 1, md: 2 }} spacing={6}>
    <Box
     display="flex"
     justifyContent="center"
     alignItems="center"
     height="100px"
     bg="gray.100"
     borderRadius="lg"
     boxShadow="lg"
    >
     <Box>
      <Icon as={FaStar} color="yellow.400" />
      <Icon as={FaStar} color="yellow.400" />
      <Icon as={FaStar} color="yellow.400" />
      <Icon as={FaStar} color="yellow.400" />
      <Icon as={FaStarHalfAlt} color="yellow.400" />
      <Text ml={2}>Based on 500+ ratings</Text>
     </Box>
    </Box>

    <Box
     display="flex"
     justifyContent="center"
     alignItems="center"
     bg="gray.100"
     borderRadius="lg"
     boxShadow="lg"
    >
     <Box>
      <Icon as={FaUsers} color="blue.500" />
      <Text ml={2} fontSize="lg">
       20,000+
       <Text color="gray.500">Registered users</Text>
      </Text>
     </Box>
    </Box>
   </SimpleGrid>
  </Container>
 );
};

// Part 3: Car Brands Section
export const CarBrandSection = () => {
 const dispatch = useDispatch();
 const { brands } = useSelector((state) => state.brands);
 useEffect(() => {
  dispatch(fetchBrands());
 }, [dispatch]);

 const displayedBrands = brands.slice(0, 10);

 return (
  <Box p={5}>
   <Heading
    fontWeight={600}
    fontSize={"1.6rem"}
    //     font-weight: 600;
    //  font-size: 2.8rem;
    mt={6}
    textAlign={"center"}
   >
    Car brands we service
   </Heading>
   <Box
    mt={6}
    display={"flex"}
    alignItems={"center"}
    justifyContent={"space-around"}
   >
    {displayedBrands?.map((brand, index) => (
     <Box key={index} display="flex" justifyContent="center">
      <Image src={brand.logo} alt={brand.name} boxSize="50px" />
     </Box>
    ))}
   </Box>
  </Box>
 );
};

// Part 4: Selected Car or Find Car Section
const CarSelectionSection = ({ selectedCar, servicesRef }) => {
 return (
  <Container mt={5}>
   <Heading as="h6" fontSize="lg" textAlign={"left"}>
    Select Your Car
   </Heading>
   {selectedCar ? (
    <CarCard selectedCar={selectedCar} />
   ) : (
    <Box>
     <FindCarForm servicesRef={servicesRef} />
    </Box>
   )}
  </Container>
 );
};

// Combined Component for all 4 sections
const WhyCarExpertPage = () => {
 const { models, selectedCar, loading, error } = useSelector(
  (state) => state.models
 );
 console.log("models:", models);
 console.log("selectedCar:", selectedCar);
 return (
  <Box>
   <FeatureCardsSection />
   {/* <RatingsAndUsersSection /> */}
   {/* <CarBrandSection /> */}
   <CarSelectionSection
    selectedCar={selectedCar}
    servicesRef={null}
    models={models}
   />
  </Box>
 );
};

export default WhyCarExpertPage;

// import React, { useEffect } from "react";
// import {
//  Box,
//  Container,
//  SimpleGrid,
//  Text,
//  Heading,
//  Icon,
//  Image,
// } from "@chakra-ui/react";
// import {
//  FaStar,
//  FaStarHalfAlt,
//  FaUsers,
//  FaHome,
//  FaCheckCircle,
// } from "react-icons/fa";
// import { useDispatch, useSelector } from "react-redux";
// import { fetchBrands } from "../../redux/brandsSlice";

// // Styled component for feature cards (converted to Chakra UI style)
// const FeatureCard = ({ icon, title }) => {
//  return (
//   <Box
//    bg="gray.100"
//    //    border="1px solid red"
//    borderRadius="lg"
//    p={4}
//    display="flex"
//    flexDirection="column"
//    alignItems="center"
//    justifyContent="space-between"
//    textAlign="center"
//    h="110px"
//    boxShadow="lg"
//   >
//    <Icon as={icon} boxSize={6} color="#000D6B" mb={2} />
//    <Text fontSize="sm">{title}</Text>
//   </Box>
//  );
// };

// // Part 1: Feature Cards Section (Why Car-Expert?)
// const FeatureCardsSection = () => {
//  const features = [
//   { icon: FaHome, title: "At-Home Convenience" },
//   { icon: FaCheckCircle, title: "10,000+ hours of experience" },
//   { icon: FaStar, title: "Trusted & Verified" },
//   { icon: FaUsers, title: "20,000+ Satisfied Users" },
//  ];

//  return (
//   <Container py={4} textAlign="center">
//    <Heading as="h6" fontSize="lg" mb={6} textAlign={"left"}>
//     Why Car-Expert?
//    </Heading>
//    <SimpleGrid columns={{ base: 2, md: 4 }} spacing={4}>
//     {features.map((feature, index) => (
//      <FeatureCard key={index} icon={feature.icon} title={feature.title} />
//     ))}
//    </SimpleGrid>
//   </Container>
//  );
// };

// // Part 2: Ratings and Registered Users Section
// const RatingsAndUsersSection = () => {
//  return (
//   <Container py={8} textAlign="center">
//    <SimpleGrid columns={{ base: 1, md: 2 }} spacing={6}>
//     <Box
//      display="flex"
//      justifyContent="center"
//      alignItems="center"
//      height="100px"
//      bg="gray.100"
//      borderRadius="lg"
//      boxShadow="lg"
//     >
//      <Box>
//       <Icon as={FaStar} color="yellow.400" />
//       <Icon as={FaStar} color="yellow.400" />
//       <Icon as={FaStar} color="yellow.400" />
//       <Icon as={FaStar} color="yellow.400" />
//       <Icon as={FaStarHalfAlt} color="yellow.400" />
//       <Text ml={2}>Based on 500+ ratings</Text>
//      </Box>
//     </Box>

//     <Box
//      display="flex"
//      justifyContent="center"
//      alignItems="center"
//      height="100px"
//      bg="gray.100"
//      borderRadius="lg"
//      boxShadow="lg"
//     >
//      <Box>
//       <Icon as={FaUsers} color="blue.500" />
//       <Text ml={2} fontSize="lg">
//        20,000+
//       </Text>
//       <Text color="gray.500">Registered users</Text>
//      </Box>
//     </Box>
//    </SimpleGrid>
//   </Container>
//  );
// };

// // Part 3: Car Brands Section
// const CarBrandSection = () => {
//  const dispatch = useDispatch();
//  const { brands } = useSelector((state) => state.brands);
//  useEffect(() => {
//   // Fetch brands when component loads
//   dispatch(fetchBrands());
//  }, [dispatch]);
//  const displayedBrands = brands.slice(0, 8);
//  return (
//   <Container textAlign="center">
//    <Heading as="h3" size="md" mb={4}>
//     Car brands we service
//    </Heading>
//    <SimpleGrid columns={{ base: 2, md: 4 }} spacing={6}>
//     {displayedBrands?.map((brand, index) => (
//      <Box key={index} display="flex" justifyContent="center">
//       <Image src={brand.logo} alt={brand.name} boxSize="50px" />
//      </Box>
//     ))}
//    </SimpleGrid>
//   </Container>
//  );
// };

// // Combined Component for all 3 sections
// const WhyCarExpertPage = () => {
//  return (
//   <Box>
//    <FeatureCardsSection />
//    <RatingsAndUsersSection />
//    <CarBrandSection />
//   </Box>
//  );
// };

// export default WhyCarExpertPage;
