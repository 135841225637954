import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

// Async thunk to fetch all car models from the API
export const fetchModels = createAsyncThunk("models/fetchModels", async () => {
 const response = await axios.get(
  "https://app-api.carexpert.org.in/api/cars/unique"
 );
 console.log("Full API response:", response.data); // Log the full response
 return response.data; // Adjust based on your API response structure
});

// Async thunk to fetch models by a specific brand ID
export const fetchModelsByBrand = createAsyncThunk(
 "models/fetchModelsByBrand",
 async (brandId) => {
  const response = await axios.get(
   `https://app-api.carexpert.org.in/api/cars/brand/${brandId}`
  );
  console.log(`Models for brand ${brandId}:`, response.data.data); // Log the response for debugging
  return response.data.data; // Assuming the models are returned in response.data
 }
);

// Async thunk to find a car by brand, model, fuelType, and transmissionType
export const findCarByDetails = createAsyncThunk(
 "models/findCarByDetails",
 async ({ brand, title, fuelType, transmissionType }) => {
  try {
   const response = await axios.get(
    "https://app-api.carexpert.org.in/api/cars/find",
    {
     params: {
      brand,
      title,
      fuelType,
      transmissionType,
     },
    }
   );
   console.log("Car found by details:", response.data); // Log the car details
   return response.data.carId; // Assuming response.data contains carId and other data
  } catch (error) {
   throw new Error(error.response?.data?.message || "Error fetching car");
  }
 }
);

// Slice to manage car models and car search by details
const modelsSlice = createSlice({
 name: "models",
 initialState: {
  models: [],
  selectedCar: JSON.parse(localStorage.getItem("selectedCar")) || "", // New state to store the found car ID
  loading: false,
  error: null,
 },
 reducers: {},
 extraReducers: (builder) => {
  builder
   // Handle fetch all models
   .addCase(fetchModels.pending, (state) => {
    state.loading = true;
   })
   .addCase(fetchModels.fulfilled, (state, action) => {
    state.loading = false;
    state.models = action.payload; // Store fetched models
   })
   .addCase(fetchModels.rejected, (state, action) => {
    state.loading = false;
    state.error = action.error.message;
   })

   // Handle fetch models by brand ID
   .addCase(fetchModelsByBrand.pending, (state) => {
    state.loading = true;
   })
   .addCase(fetchModelsByBrand.fulfilled, (state, action) => {
    state.loading = false;
    state.models = action.payload; // Store fetched models for the selected brand
   })
   .addCase(fetchModelsByBrand.rejected, (state, action) => {
    state.loading = false;
    state.error = action.error.message;
   })

   // Handle finding a car by details (brand, model, fuelType, transmissionType)
   .addCase(findCarByDetails.pending, (state) => {
    state.loading = true;
    state.error = null;
   })
   // Handle findCarByDetails fulfilled state
   .addCase(findCarByDetails.fulfilled, (state, action) => {
    state.loading = false;
    state.selectedCar = action.payload; // Store the fetched car data in Redux state

    // Store the car details in local storage
    localStorage.setItem("selectedCar", JSON.stringify(action.payload));
   })
   // Handle findCarByDetails rejected state
   .addCase(findCarByDetails.rejected, (state, action) => {
    state.loading = false;
    state.error = action.error.message;
   });
 },
});

export default modelsSlice.reducer;
