import { Box } from "@chakra-ui/react";
import React from "react";
import DynamicUI from "./DynamicUI";
import FormBanner from "./FormBanner";
import HorizontalStepper from "./FormComponent";
import FormTestimonials from "./FormTestimonials";
import WorkshopSlider from "./WorkshopSlider";
import SignUpSteps from "./SignUpSteps";

const RegisterComponent = () => {
 return (
  <Box>
   <FormBanner />
   <SignUpSteps />
   <DynamicUI />
   <FormTestimonials />
   <WorkshopSlider />
   <HorizontalStepper />
  </Box>
 );
};

export default RegisterComponent;
