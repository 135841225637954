import React from 'react'

const CarListing = () => {
  return (
    <div>
      CarListing
    </div>
  )
}

export default CarListing
