import React, { useState } from "react";
import {
 Box,
 Flex,
 Text,
 Input,
 Textarea,
 Button,
 VStack,
 useToast,
 FormControl,
} from "@chakra-ui/react";
import { FaFacebookF, FaTwitter, FaLinkedinIn } from "react-icons/fa";
import { CiInstagram } from "react-icons/ci";
import axios from "axios";

const Contact = () => {
 const [formData, setFormData] = useState({
  name: "",
  email: "",
  mobile: "",
  description: "",
 });

 const toast = useToast();

 const handleChange = (e) => {
  setFormData({
   ...formData,
   [e.target.name]: e.target.value,
  });
 };

 const handleSubmit = async (e) => {
  e.preventDefault();
  try {
   const response = await axios.post(
    "https://app-api.carexpert.org.in/api/contact",
    formData
   );
   console.log("Form data submitted:", response.data);
   toast({
    title: "description Sent.",
    description:
     "We've received your description and will get back to you shortly.",
    status: "success",
    duration: 5000,
    isClosable: true,
   });

   setFormData({
    name: "",
    email: "",
    mobile: "",
    description: "",
   });
  } catch (error) {
   console.log("Error submitting form:", error);

   // Show error toast
   toast({
    title: "Submission Failed.",
    description:
     "There was an error submitting your description. Please try again later.",
    status: "error",
    duration: 5000,
    isClosable: true,
   });
  }
 };

 return (
  <Box color="white">
   {/* Google Map Section */}
   <Box w="100%" h="400px">
    <iframe
     src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d224345.83972741933!2d76.73121866536642!3d28.459496815668325!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x390d18d11f54fe91%3A0x1dbe40fd6aa6f414!2sFixiGo%20-%20Delhi%20Automobiles!5e0!3m2!1sen!2sin!4v1694603019751!5m2!1sen!2sin"
     width="100%"
     height="100%"
     style={{ border: 0 }}
     allowFullScreen=""
     loading="lazy"
     referrerPolicy="no-referrer-when-downgrade"
    />
   </Box>

   <Flex
    direction={["column", "column", "row"]}
    w={{ base: "90%", md: "80%" }}
    alignItems="center"
    justifyContent="space-between"
    margin="auto"
    py={10}
    gap={14}
   >
    <Flex
     bgGradient="linear(to-b, #000D6B, #1A237E)"
     flexDirection="column"
     gap={6}
     border="2px solid white"
     p={8}
     borderRadius="lg"
     w={{ base: "100%", md: "40%", lg: "45%" }}
     boxShadow="xl"
     color="white"
     justifyContent={"center"}
    >
     <Box>
      <Text fontSize={{ base: "1.4rem", md: "2.2rem" }} fontWeight="bold">
       Quick Contact
      </Text>
      <Text fontSize={{ base: "1rem", md: "1.2rem" }} mt={2}>
       Have questions? Reach out to us using the details below.
      </Text>
     </Box>

     <Box>
      <Text fontSize={{ base: "1rem", md: "1.3rem" }}>
       <strong>Franchise Location :</strong>{" "}
       Noida,Delhi,Bengalore,Hyderabad,U.P,Pune,Nagpur
       <p>Mumbai,Chennai,Coimbatore, Etc</p>
      </Text>
     </Box>

     <Box>
      <Text fontSize={{ base: "1rem", md: "1.3rem" }}>
       <strong>Contact Us :</strong> care@carexpert.org.in
      </Text>
     </Box>

     <Flex gap={5} mt={4}>
      <Button
       as="a"
       href="#"
       bg="white"
       color="#000D6B"
       borderRadius="full"
       w={10}
       h={10}
       _hover={{ bg: "#e3f2fd", transform: "scale(1.05)" }}
       transition="all 0.3s ease"
      >
       <FaFacebookF size={24} />
      </Button>

      <Button
       as="a"
       href="#"
       bg="white"
       color="#000D6B"
       borderRadius="full"
       w={10}
       h={10}
       _hover={{ bg: "#e3f2fd", transform: "scale(1.05)" }}
       transition="all 0.3s ease"
      >
       <FaTwitter size={24} />
      </Button>

      <Button
       as="a"
       href="#"
       bg="white"
       color="#000D6B"
       borderRadius="full"
       h={10}
       w={10}
       _hover={{ bg: "#e3f2fd", transform: "scale(1.05)" }}
       transition="all 0.3s ease"
      >
       <FaLinkedinIn size={24} />
      </Button>
     </Flex>
    </Flex>

    <Box
     as="form"
     bg="white"
     p={10}
     borderRadius="lg"
     boxShadow="xl"
     transform="translateY(-20px)"
     w={{ base: "100%", md: "50%" }}
     onSubmit={handleSubmit}
    >
     <Text
      fontSize={{ base: "1.5rem", md: "2.2rem" }}
      fontWeight="bold"
      color="black"
      mb={6}
     >
      Send Us a description
     </Text>
     <VStack spacing={5}>
      <Flex
       width={"100%"}
       gap={4}
       flexDirection={{ base: "column", md: "row" }}
      >
       <FormControl isRequired>
        <Input
         name="name"
         value={formData.name}
         onChange={handleChange}
         placeholder="Your Name"
         width={"100%"}
         bg="gray.100"
         color="black"
         _focus={{ bg: "white", borderColor: "#000D6B" }}
        />
       </FormControl>
       <FormControl isRequired>
        <Input
         name="email"
         value={formData.email}
         onChange={handleChange}
         placeholder="Your Email Address"
         width={"100%"}
         bg="gray.100"
         color="black"
         _focus={{ bg: "white", borderColor: "#000D6B" }}
        />
       </FormControl>
      </Flex>
      <Input
       name="mobile"
       value={formData.mobile}
       onChange={handleChange}
       placeholder="mobile"
       bg="gray.100"
       color="black"
       _focus={{ bg: "white", borderColor: "#000D6B" }}
      />
      <Textarea
       name="description"
       value={formData.description}
       onChange={handleChange}
       placeholder="Your description..."
       bg="gray.100"
       color="black"
       _focus={{ bg: "white", borderColor: "#000D6B" }}
      />
      <Button
       type="submit"
       bgGradient="linear(to-r, #e53935, #d32f2f)"
       color="white"
       w="full"
       _hover={{ bgGradient: "linear(to-r, #ef5350, #e57373)" }}
       transition="all 0.3s ease"
       fontSize="1.2rem"
       py={6}
      >
       Submit
      </Button>
     </VStack>
    </Box>
   </Flex>
  </Box>
 );
};

export default Contact;
