import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import "bootstrap/dist/css/bootstrap.min.css";
import "remixicon/fonts/remixicon.css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { Button, Typography, Container } from "@mui/material";

// Create a custom theme

import { BrowserRouter as Router } from "react-router-dom";
import { ChakraProvider } from "@chakra-ui/react";
import { Provider } from "react-redux";
import store from "./redux/store";
console.log("store:", store.getState());
const theme = createTheme({
 palette: {
  primary: {
   main: "#1976d2", // Custom primary color
  },
  secondary: {
   main: "#9c27b0", // Custom secondary color
  },
 },
 typography: {
  h1: {
   fontSize: "2.5rem",
   fontWeight: 700,
  },
  body1: {
   fontSize: "1.2rem",
  },
 },
});
const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
 <ThemeProvider theme={theme}>
  <Provider store={store}>
   <ChakraProvider>
    <Router>
     <App />
    </Router>
   </ChakraProvider>
  </Provider>
 </ThemeProvider>
);
