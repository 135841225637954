import React from "react";
import { Container } from "reactstrap";
import { Box, Heading, Text } from "@chakra-ui/react";
import MultipleItems from "./MultipleItems"; // Import the carousel component

const OriginalSpareParts = () => {
 return (
  <Box>
   <Container>
    <Heading textAlign="center" mb={6} fontWeight={"600"} fontSize={"1.5rem"}>
     Original Spare Parts
    </Heading>
    <Text textAlign={"center"}>Extended Warranty with Car Expert</Text>
    {/* Carousel component */}
    <MultipleItems />
   </Container>
  </Box>
 );
};

export default OriginalSpareParts;
